/* tslint:disable:no-use-before-declare */
import { ActionNames, ErrorAction } from './action.types';
export * from './action.types';
import { LoginActions } from '@fuselab/ui-fabric/actions/login';
export { LoginAction, LogoutAction } from '@fuselab/ui-fabric/actions/login';

//tslint:disable:no-reserved-keywords
export interface NoopAction {
  type: ActionNames.noop;
}

export interface SetAccessToken extends ErrorAction {
  type: ActionNames.login.setAccessToken;
  token: string;
}

export type PreferenceNames = 'compact' | 'theme';

export type Action = NoopAction |
  LoginActions | SetAccessToken;
