import * as Login from '@fuselab/ui-fabric/actions/login';
import * as Theme from '@fuselab/ui-fabric/actions/theme';
// tslint:disable:no-reserved-keywords
export interface ErrorAction {
  error?: Error;
}

// keep ActionNames sorted
export enum ActionNames {
  noop
}

export namespace ActionNames {
  export import theme = Theme.ThemeActions;
  export import login = Login.Actions;
}
