import { getLoggedInUser } from '@fuselab/ui-fabric/actions/login';
import { BaseStore } from '@fuselab/ui-fabric/models';
import { loadPreference } from '@fuselab/ui-fabric/themes/preference';
//tslint:disable-next-line
const { appName, version, devServerHost } = require('./package.json');
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { App } from './components/app/app';
import reducers from './reducers';
import rootSaga from './sagas';
declare const BASENAME: string;

const sagaMiddleware = createSagaMiddleware();
const store: any = createStore<BaseStore>(
  reducers,
  {
    user: null,
    preference: loadPreference(),
    loginHostName: devServerHost
  },
  applyMiddleware(sagaMiddleware)
);
store.runSaga = sagaMiddleware.run;
store.runSaga(rootSaga);
store.dispatch(getLoggedInUser());

const history = createBrowserHistory({
  basename: BASENAME
});

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
