import { LogoHeader } from '@fuselab/ui-fabric/logoHeader';
import { User } from '@fuselab/ui-fabric/userProfile';
import { IBaseProps } from '@uifabric/utilities';
import { BaseComponent } from '@uifabric/utilities/lib';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import * as React from 'react';
import classNames from './topHeader.classNames';

export interface TopHeaderProps extends IBaseProps {
  user?: string;
}

export interface TopHeaderState {
  expandNav: boolean;
}

/**
 * Black bar that runs across the top of the landing page, with navigation buttons
 */
export class TopHeader extends BaseComponent<TopHeaderProps, TopHeaderState> {
  constructor(props: TopHeaderProps) {
    super(props);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.state = { expandNav: false };
  }

  public render(): JSX.Element {
    return (
      <section className={classNames().topHeader} role='banner'>
        <a href='#MainContent' className={classNames().skipToMain}>Skip to main content</a>
        <div className={classNames().logoDiv}>
          <LogoHeader>
            <div className={classNames().upperRight}>
              <IconButton
                className={classNames().navMenuButton}
                iconProps={{ iconName: 'GlobalNavButton' }}
                title='Nav Menu'
                ariaLabel='Nav Menu'
                onClick={this.toggleExpand}
              />
              <div className={classNames().user}>
                <User
                  darkTopNav={true}
                  disableThemeToggle={true}
                  disableCompactToggle={true}
                />
              </div>
            </div>
          </LogoHeader>
        </div>
        <div className={`${classNames().headerNav} ${this.state.expandNav ? classNames().expand : ''}`} role='menu'>
          <a href='/bots' role='menuitem'>
            My Bots
          </a>
          <a href='https://aka.ms/bot-framework-www-portal-docs' role='menuitem'>
            Documentation
          </a>
          <a href='https://blog.botframework.com' role='menuitem'>
            Blog
          </a>
          <a href='https://aka.ms/bot-framework-www-portal-emulator' role='menuitem'>
            Emulator
          </a>
          <a href='https://luis.ai/' role='menuitem'>
            LUIS
          </a>
          <a href='https://www.qnamaker.ai/' role='menuitem'>
            QnA Maker
          </a>
        </div>
      </section>
    );
  }

  private toggleExpand(): void {
    this.setState({
      expandNav: !this.state.expandNav
    });
  }
}
