import * as React from 'react';
import classNames from './quickStart.classNames';

export interface QuickStartProps extends React.Props<any> {
  text: string;
  imageUrl: string;
  href: string;
  ariaLabel?: string;
}

export const QuickStart = (props: QuickStartProps) => (
  <div className={classNames().quickStart}>
    <a href={props.href} className={classNames().quickStartLink} aria-label={props.ariaLabel || props.text}>
      <img
        src={props.imageUrl}
        alt=''
        role='presentation'
        className={classNames().quickStartImage}
      />
      {props.text}
    </a>
  </div>
);
