import * as React from 'react';
import classNames from './customerCard.classNames';

export interface CustomerCardProps extends React.Props<any> {
  text: string;
  imageName: string;
  altText: string;
  href: string;
}

export const CustomerCard = (props: CustomerCardProps) => (
  <a className={classNames().customerCard} href={props.href} target='_blank' rel='noopener noreferrer'>
    <div className={classNames().customerCardImageHolder}>
      <img
        src={props.imageName}
        alt={props.altText}
        className={classNames().customerCardImage}
      />
    </div>
    <p>{props.text}</p>
  </a>
);
