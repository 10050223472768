import { mergeStyleSets } from '@uifabric/merge-styles';
import { AzureColors } from '../app/app.classNames';

//tslint:disable-next-line
const quickStartClassNames = (): { [key: string]: string } => mergeStyleSets({
  quickStart: {
    width: 120,
    display: 'inline-block'
  },
  quickStartLink: {
    fontSize: 24,
    textAlign: 'center',
    margin: 'auto',
    display: 'block',
    color: AzureColors.darkBlue
  },
  quickStartImage: {
    height: 58,
    width: 58,
    margin: 'auto',
    display: 'block'
  }
});

export default quickStartClassNames;
