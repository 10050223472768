import { mergeStyleSets } from '@uifabric/merge-styles';

//tslint:disable-next-line
const topHeaderClassNames = (): { [key: string]: string } => mergeStyleSets({
  topHeader: {
    backgroundColor: '#000',
    paddingTop: 24,
    selectors: {
      '@media (max-width: 640px)': {
        paddingBottom: 6
      },
      '@media screen and (-ms-high-contrast: black-on-white)': {
        selectors: {
          img: {
            backgroundColor: '#000'
          }
        }
      }
    }
  },
  skipToMain: {
    position: 'absolute',
    left: -999,
    selectors: {
      ':focus': {
        position: 'fixed',
        backgroundColor: '#e6e6e6',
        background: '#6e6e6',
        top: 0,
        left: 0,
        right: 0,
        height: 60,
        lineHeight: 60,
        maxWidth: 'none',
        textAlign: 'center',
        outline: 'none'
      }
    }
  },
  upperRight: {
    float: 'right'
  },
  user: {
    display: 'inline-flex'
  },
  navMenuButton: {
    color: '#FFF',
    selectors: {
      '@media (min-width: 640px)': {
        display: 'none'
      }
    }
  },
  headerNav: {
    position: 'relative',
    listStyleType: 'none',
    height: 45,
    selectors: {
      a: {
        display: 'inline-block',
        fontSize: 15,
        lineHeight: 45,
        color: '#FFF',
        textDecoration: 'none',
        paddingLeft: 16,
        paddingRight: 16,
        height: 45,
        selectors: {
          ':hover': {
            backgroundColor: '#393939'
          },
          '@media (max-width: 640px)': {
            width: '80%',
            borderTop: '1px solid #FFF',
            backgroundColor: '#000',
            position: 'relative',
            zIndex: 100,
            marginBottom: 6,
            right: -10
          }
        }
      },
      '@media (max-width: 640px)': {
        width: '100%',
        height: 303,
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },
  logoDiv: {
    marginBottom: 8
  },
  expand: {
    display: 'block'
  }
});

export default topHeaderClassNames;
