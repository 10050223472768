import { mergeStyleSets } from '@uifabric/merge-styles';

//tslint:disable-next-line
const customerCardClassNames = (): { [key: string]: string } => mergeStyleSets({
  customerCard: {
    display: 'block',
    minHeight: 184,
    width: 213,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '24px 28px 12px',
    color: 'black',
    border: '1px solid #EBEBEB',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 7px 0 rgba(0,0,0,0.5), 0 3px 6px 0 rgba(223,223,223,0.5)',
    selectors: {
      ':hover': {
        textDecoration: 'none !important', // Need to overwrite 'root a:hover' rule from app.classNames.ts
        boxShadow: 'rgba(0, 0, 0, 0.5) 1px 8px 10px 0px, rgba(223, 223, 223, 0.5) 0px 10px 10px 0px'
      },
      ':focus': {
        outline: '#000 dashed 1px',
        outlineOffset: '-4px',
        boxShadow: 'rgba(0, 0, 0, 0.5) 1px 8px 10px 0px, rgba(223, 223, 223, 0.5) 0px 10px 10px 0px'
      }
    }
  },
  customerCardImageHolder: {
    height: 38
  },
  customerCardImage: {
    maxHeight: 38,
    maxWidth: 120
  }
});

export default customerCardClassNames;
