import { BaseStore } from '@fuselab/ui-fabric/models';
import { parseCookie } from '@fuselab/ui-shared/parse-cookie';
import { isLocalhost } from '@fuselab/ui-shared/utils';
import { AccountClient, ajaxHeaders, AuthData } from '@intercom/api-generated/FetchRestClient';
import { all, call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionNames, LoginAction, LogoutAction, SetAccessToken } from '../actions';
import { UserInfo } from '../models';

declare const BASENAME: string;

const intercomTokenName = 'intercom-token';
const csrfCookieName = 'Csrf-Token';
const csrfHeaderName = 'X-Csrf-Token';
const bfAuthCookieHeaderName = 'x-bf-auth-cookie';

function* loginUser(setAccessToken: SetAccessToken) {
  const { token } = setAccessToken;
  try {
    sessionStorage.setItem(intercomTokenName, token);
    yield requireUserSignedIn();
  } catch (error) {
    sessionStorage.removeItem(intercomTokenName);
    yield put({ type: ActionNames.login.error, error });
  }
}

function* logoutUser(action: LogoutAction) {
  if (isLocalhost()) {
    sessionStorage.removeItem(intercomTokenName);
    delete ajaxHeaders.Authorization;
  } else {
    delete ajaxHeaders[csrfHeaderName];
    if (action.user) {
      window.location.href = `/identity/signout?postLogoutRedirectUri=${encodeURIComponent(BASENAME)}`;
    }
  }
}

function* watchAccessToken() {
  yield takeLatest(ActionNames.login.setAccessToken, loginUser);
}

function* checkLogin(loginAction: LoginAction) {
  if (isLocalhost()) {
    const token = sessionStorage.getItem(intercomTokenName);
    if (token) {
      yield put({ type: ActionNames.login.setAccessToken, token });
    } else {
      yield call(loginAction.history.push, { pathname: '/login' });
    }
  } else {
    window.location.href = `/identity/signin?requestUrl=${encodeURIComponent(BASENAME)}`;
  }
}

function* watchLogin() {
  yield takeEvery(ActionNames.login.logIn, checkLogin);
}

function* watchLogout() {
  yield takeEvery(ActionNames.login.logOut, logoutUser);
}

function* watchGetUser() {
  yield takeLatest(ActionNames.login.getUser, requireUserSignedIn);
}

export function* requireUserSignedIn() {
  const curUser = yield select<BaseStore>(x => x.user);
  if (!curUser) {
    try {
      if (isLocalhost()) {
        let token = sessionStorage.getItem(intercomTokenName);
        if (!token) {
          const setAccessToken = yield take(ActionNames.login.setAccessToken);
          token = setAccessToken.token;
          sessionStorage.setItem(intercomTokenName, token);
        }
        const { SelfToken, AuthCookie } = JSON.parse(token);
        ajaxHeaders.Authorization = `Bearer ${SelfToken}`;
        ajaxHeaders[bfAuthCookieHeaderName] = AuthCookie;
      } else {
        // tslint:disable-next-line:no-cookies
        const cookies = parseCookie(document.cookie);
        const csrfToken = cookies[csrfCookieName];
        ajaxHeaders[csrfHeaderName] = csrfToken;
      }
      const authData: AuthData = yield call(AccountClient.getSignedInUser);
      const userInfo: UserInfo = { displayName: authData.FirstName, email: authData.EmailAddress };
      yield put({ type: ActionNames.login.getUserResult, user: userInfo });
    } catch (error) {
      yield put({ type: ActionNames.login.error, error });
      yield put({ type: ActionNames.login.logOut });

      return false;
    }
  }

  return true;
}

export function* handleLogin() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchAccessToken),
    fork(watchGetUser)
  ]);
}
