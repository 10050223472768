//tslint:disable-next-line
const { appName } = require('../../package.json');
/* tslint:disable:no-use-before-declare */
import { mergeStyleSets } from '@uifabric/merge-styles';

const imagesRoot = `${window.cdnRoot}/assets/${appName}/images`;
// Azure brand colors
export const AzureColors = {
  darkBlue: '#0078D4',
  lightBlue: '#50E6FF'
};
// other colors
export const Colors = {
  greenButton: '#8FCB24',
  greenHover: '#6BB700',
  greenActive: '#599B00',
  darkGray: '#1A1A1E'
};

const quoteImgStyle: any = {
  float: 'left',
  height: 32,
  width: 56,
  paddingTop: 8,
  objectFit: 'cover',
  objectPosition: 'left'
};

//tslint:disable-next-line
const appMainClassNames = (): { [key: string]: string } =>
  mergeStyleSets({
    full: {
      width: '100%',
      overflowY: 'auto',
      height: 'calc(100vh)',
      selectors: {
        '> * > *': {
          maxWidth: 1440,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: 80,
          paddingRight: 80
        },
        '@media (max-width: 1024px)': {
          selectors: {
            '> * > *': {
              paddingLeft: 32,
              paddingRight: 32
            }
          }
        },
        '@media (max-width: 640px)': {
          selectors: {
            '> * > *': {
              paddingLeft: 16,
              paddingRight: 16
            }
          }
        }
      }
    },
    root: {
      paddingBottom: 40,
      selectors: {
        a: {
          textDecoration: 'none',
          selectors: {
            ':hover': {
              textDecoration: 'underline'
            }
          }
        },
        '#life-cycle a': {
          textDecoration: 'underline'
        }
      }
    },
    flexRow: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    // Safari is having issues with exact width calculations, setting it to one less pixel works though
    sm6: {
      width: 'calc(50% - 1px)'
    },
    lg4: {
      selectors: {
        '@media (min-width: 640px)': {
          width: 'calc(33.3333% - 1px)'
        }
      }
    },
    lg6: {
      selectors: {
        '@media (min-width: 640px)': {
          width: 'calc(50% - 1px)'
        }
      }
    },
    xl2: {
      selectors: {
        '@media (min-width: 1024px)': {
          width: 'calc(16.666666% - 1px)'
        }
      }
    },
    xl3: {
      selectors: {
        '@media (min-width: 1024px)': {
          width: 'calc(25% - 1px)'
        }
      }
    },
    xl4: {
      selectors: {
        '@media (min-width: 1024px)': {
          width: 'calc(33.3333% - 1px)'
        }
      }
    },
    xl6: {
      selectors: {
        '@media (min-width: 1024px)': {
          width: 'calc(50% - 1px)'
        }
      }
    },
    xl8: {
      selectors: {
        '@media (min-width: 1024px)': {
          width: 'calc(66.6666% - 1px)'
        }
      }
    },
    xxl4: {
      selectors: {
        '@media (min-width: 1366px)': {
          width: 'calc(33.3333% - 1px)'
        }
      }
    },
    centeredHeader: {
      textAlign: 'center',
      lineHeight: 48
    },
    colorBandDivider: {
      height: 6,
      width: 96,
      margin: '28px auto',
      backgroundImage: `url(${imagesRoot}/Color_Band_Divider.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    heroSection: {
      backgroundColor: AzureColors.darkBlue,
      color: '#FFFFFF',
      paddingTop: 52,
      paddingBottom: 52,
      fontWeight: 600,
      selectors: {
        h1: {
          fontSize: 36
        },
        p: {
          fontSize: 18,
          letterSpacing: '0.23px'
        }
      }
    },
    heroSectionButtons: {
      marginTop: 55,
      lineHeight: 60,
      selectors: {
        '.ms-Button:hover': {
          textDecoration: 'none'
        },
        '.ms-Button:focus': {
          outline: 'auto'
        }
      }
    },
    azureButton: {
      backgroundColor: Colors.greenButton,
      color: '#000',
      fontSize: 16,
      marginRight: 38,
      paddingTop: 24,
      paddingBottom: 24,
      selectors: {
        ':hover': {
          backgroundColor: Colors.greenHover
        },
        ':active': {
          backgroundColor: Colors.greenActive
        }
      }
    },
    githubButton: {
      backgroundColor: AzureColors.lightBlue,
      color: '#000',
      fontSize: 16,
      paddingTop: 24,
      paddingBottom: 24,
      selectors: {
        ':hover,': {
          backgroundColor: AzureColors.lightBlue
        },
        ':active': {
          backgroundColor: AzureColors.lightBlue
        }
      }
    },
    tableOfContents: {
      zIndex: 100,
      marginTop: -25,
      overflow: 'auto',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5), 2px 3px 12px 0 rgba(0,0,0,0.5)',
      selectors: {
        a: {
          display: 'inline-block',
          height: 40,
          paddingLeft: 20,
          paddingRight: 20,
          color: '#000',
          fontSize: 16,
          lineHeight: 40,
          margin: 4,
          selectors: {
            ':hover': {
              textDecoration: 'none',
              backgroundColor: '#ebebeb'
            }
          }
        }
      }
    },
    featureRow: {
      selectors: {
        '@media (min-width: 1024px)': {
          marginLeft: -40,
          marginRight: -40
        }
      }
    },
    featuresSection: {
      marginBottom: 28,
      paddingTop: 48
    },
    featureCard: {
      paddingLeft: 40,
      paddingRight: 40,
      selectors: {
        '@media (max-width: 1024px)': {
          selectors: {
            ':nth-child(2n - 1)': {
              paddingLeft: 32
            },
            ':nth-child(2n)': {
              paddingRight: 32
            }
          }
        },
        '@media (max-width: 640px)': {
          selectors: {
            ':nth-child(2n - 1)': {
              paddingLeft: 16
            },
            ':nth-child(2n)': {
              paddingRight: 16
            }
          }
        }
      }
    },
    featureIcon: {
      width: 69,
      height: 69,
      selectors: {
        '@media (max-width: 1024px)': {
          width: 38,
          height: 38
        }
      }
    },
    imageSection: {
      background: 'linear-gradient(34.09deg, #FFFFFF 0%, #EBEBEB 100%)',
      marginBottom: 36,
      paddingTop: 40,
      selectors: {
        p: {
          fontSize: 24,
          lineHeight: 32,
          selectors: {
            '@media (max-width: 480px)': {
              fontSize: 13,
              lineHeight: 17
            }
          }
        },
        '@media (max-width: 1024px)': {
          paddingTop: 0
        }
      }
    },
    phoneImageContainer: {
      height: 390,
      overflow: 'hidden'
    },
    phoneImage: {
      maxWidth: 420,
      width: '100%',
      margin: '0 auto',
      display: 'block'
    },
    lineDivider: {
      height: 2,
      width: '65%',
      margin: '44px auto 76px',
      backgroundColor: '#757575',
      selectors: {
        '@media (max-width: 480px)': {
          marginBottom: 32
        }
      }
    },
    customersColumn: {
      maxWidth: 600,
      margin: 'auto',
      fontSize: 16
    },
    customerCardsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      selectors: {
        '@media (min-width: 1365px)': {
          marginRight: -36
        },
        '> div': {
          marginBottom: 8,
          selectors: {
            '@media (min-width: 1365px)': {
              marginBottom: 48
            }
          }
        }
      }
    },
    fullWidthImage: {
      width: '100%'
    },
    componentsColumn: {
      lineHeight: 28,
      fontSize: 16,
      selectors: {
        '@media (min-width: 640px)': {
          width: 'calc(33.3333333333% - 1px)'
        },
        '@media (min-width: 1024px)': {
          width: 'calc(20% - 1px)'
        },
        '@media (max-width: 640px)': {
          selectors: {
            img: {
              maxHeight: 40,
              maxWidth: 40,
              float: 'left'
            },
            h3: {
              marginTop: 0,
              marginLeft: 56
            },
            p: {
              marginTop: 0,
              marginLeft: 56
            }
          }
        }
      }
    },
    componentsImage: {
      maxHeight: 61,
      maxWidth: 71
    },
    learnMoreLink: {
      textTransform: 'uppercase',
      color: AzureColors.darkBlue,
      selectors: {
        ':hover': {
          textDecoration: 'underline'
        }
      }
    },
    quoteSection: {
      backgroundColor: '#000',
      padding: '46px 55px 46px',
      marginBottom: 48
    },
    quoteParagraph: {
      margin: 0,
      color: AzureColors.lightBlue,
      fontSize: 24,
      lineHeight: 35,
      fontWeight: 600,
      textIndent: '-10px',
      selectors: {
        '@media (max-width: 640px)': {
          fontSize: 18,
          lineHeight: 24,
          textIndent: '-6px'
        }
      }
    },
    quoteByLine: {
      marginTop: 46,
      color: '#FFF',
      fontSize: 16,
      lineHeight: 24,
      fontWeight: 600,
      selectors: {
        img: quoteImgStyle,
        div: {
          marginLeft: 56
        },
        p: {
          margin: 0
        }
      }
    },
    lifeCycleImage: {
      width: '100%',
      selectors: {
        '@media (max-width: 640px)': {
          width: '100%'
        }
      }
    },
    lifecycleColumn: {
      selectors: {
        ul: {
          paddingLeft: 15,
          fontSize: 16,
          lineHeight: 26,
          selectors: {
            a: {
              color: AzureColors.darkBlue
            }
          }
        },
        h3: {
          marginLeft: -5
        }
      }
    },
    quickStartSection: {
      paddingBottom: 48
    },
    quickstartRow: {
      margin: '32px auto',
      maxWidth: 360
    },
    composerSection: {
      backgroundColor: Colors.darkGray,
      color: 'white',
      padding: '42px 12px',
      backgroundImage: `url(${imagesRoot}/Composer_Background.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      fontSize: '16px',
      selectors: {
        '> div': {
          display: 'flex',
          selectors: {
            '@media (max-width: 640px)': {
              flexWrap: 'wrap'
            }
          }
        },
        '@media (max-width: 1024px)': {
          backgroundPosition: `${1024 - 530}px`
        },
        a: {
          color: AzureColors.lightBlue
        },
        img: {
          width: '222px'
        }
      }
    },
    composerSectionImage: {
      width: '222px',
      selectors: {
        '@media (max-width: 640px)': {
          width: '100%',
          flexShrink: 0
        }
      }
    },
    composerSectionContent: {
      marginLeft: '60px',
      marginRight: '230px',
      selectors: {
        '@media (max-width: 1024px)': {
          maxWidth: `${1024 - 530 - 111}px`,
          marginRight: '0px'
        },
        '@media (max-width: 640px)': {
          maxWidth: 'max-content',
          marginLeft: '0px'
        },
        '> p:last-child': {
          fontSize: '14px'
        }
      }
    },
    underlinedLink: {
      textDecoration: 'underline !important'
    },
    composerLinkDiv: {
      marginTop: '40px'
    },
    composerLink: {
      border: `solid 1px ${AzureColors.lightBlue}`,
      padding: '6px 12px',
      selectors: {
        '::after': {
          content: '"›"',
          color: 'white',
          marginLeft: '50px',
          fontSize: '26px',
          lineHeight: '0'
        },
        ':hover': {
          textDecoration: 'none !important',
          borderColor: 'white',
          color: 'white'
        },
        ':focus': {
          outline: 'dotted 1px white',
          outlineOffset: '2px'
        }
      }
    },
    azureDisclaimer: {
      color: '#75757A',
      textAlign: 'center',
      fontSize: 12,
      maxWidth: 600,
      margin: '0 auto'
    },
    footerSection: {
      paddingTop: 52,
      paddingBottom: 36,
      backgroundColor: '#EBEBEB',
      lineHeight: 24,
      selectors: {
        h2: {
          fontWeight: 600,
          fontSize: 24
        },
        'li a': {
          color: '#000'
        }
      }
    },
    footerColumn: {
      selectors: {
        ul: {
          listStyleType: 'none',
          paddingTop: 12,
          paddingLeft: 0
        },
        li: {
          marginBottom: 12
        }
      }
    },
    copyrightSection: {
      float: 'right',
      marginTop: 16,
      selectors: {
        '> *': {
          marginLeft: 28
        },
        '@media (max-width: 640px)': {
          float: 'left',
          selectors: {
            '> *': {
              display: 'block',
              marginLeft: 0
            }
          }
        }
      }
    }
  });

export default appMainClassNames;
