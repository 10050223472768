//tslint:disable-next-line
const { appName } = require('../../package.json');
/* tslint:disable:no-use-before-declare */
import { WithAuthAttributes } from '@fuselab/ui-fabric/withAuth';
import { initializeIcons } from '@uifabric/icons';
import { Fabric } from 'office-ui-fabric-react/lib-commonjs/Fabric';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import * as React from 'react';
import { CustomerCard } from '../customerCard';
import { QuickStart } from '../quickStart';
import { TopHeader } from '../topHeader';
import classNames from './app.classNames';
/* tslint:enable:no-use-before-declare */
declare global {
  interface Window {
    cdnRoot: string;
    WcpConsent: any;
    siteConsent: any;
  }
}

if (window.cdnRoot) {
  initializeIcons(`${window.cdnRoot}/assets/fonts/`);
} else {
  // Register icons and pull the fonts from the default SharePoint cdn:
  initializeIcons();
}

if (window.WcpConsent) {
  window.WcpConsent.init('en-US', 'cookie-banner', function (err, _siteConsent) {
    if (!err) {
      window.siteConsent = _siteConsent;
    }
  });
}

const imagesRoot = `${window.cdnRoot}/assets/${appName}/images`;

export type AppAttributes = WithAuthAttributes;

//tslint:disable:max-func-body-length
export const App = () => {
  return (
    <Fabric className={classNames().root}>
      <div className={classNames().full} role='main'>
        <TopHeader />
        <section id='MainContent' className={classNames().heroSection} aria-labelledby='Microsoft Bot Framework'>
          <div>
            <h1>
              Microsoft Bot Framework
            </h1>
            <p>
              A comprehensive framework for building enterprise-grade conversational AI experiences.
            </p>
            <div className={classNames().heroSectionButtons}>
              <DefaultButton
                text='Try Azure Bot Service for Free'
                href='https://aka.ms/bot-framework-try-abs-free'
                className={classNames().azureButton}
              />
              <DefaultButton
                text='Download SDK from Github'
                href='https://aka.ms/bot-framework-github'
                className={classNames().githubButton}
              />
            </div>
          </div>
        </section>
        <section>
          <div role='navigation'>
            <div className={classNames().tableOfContents}>
              <a href='#customers' aria-label='Customers, same page'>Customers</a>
              <a href='#cognitive-services' aria-label='Cognitive Services, same page'>Cognitive Services</a>
              <a href='#life-cycle' aria-label='Bot Life Cycle, same page'>Bot Life Cycle</a>
              <a href='#quick-starts' aria-label='Quick Starts, same page'>Quick Starts</a>
            </div>
          </div>
        </section>
        <section className={classNames().featuresSection} aria-labelledby='AI and natural language'>
          <div className='ms-Grid' dir='ltr'>
            <div className={`${classNames().featureRow} ${classNames().flexRow} ms-Grid-row`}>
              <div className={`${classNames().featureCard} ms-Grid-col ${classNames().sm6} ${classNames().xl3}`}>
                <img
                  src={`${imagesRoot}/AI_Natural_Lang_Icon.png`}
                  role='presentation'
                  alt=''
                  className={classNames().featureIcon}
                />
                <h2>AI and natural language</h2>
                <p>Create a bot with the ability to speak, listen, understand, and learn from your users with Azure Cognitive Services.</p>
              </div>
              <div className={`${classNames().featureCard} ms-Grid-col ${classNames().sm6} ${classNames().xl3}`}>
                <img
                  src={`${imagesRoot}/Write_Once_Icon.png`}
                  role='presentation'
                  alt=''
                  className={classNames().featureIcon}
                />
                <h2>Open & Extensible</h2>
                <p>Benefit from open source SDK and tools to build, test, and connect bots that interact naturally with users, wherever they are.</p>
              </div>
              <div className={`${classNames().featureCard} ms-Grid-col ${classNames().sm6} ${classNames().xl3}`}>
                <img
                  src={`${imagesRoot}/Enterprise_Grade_Icon.png`}
                  role='presentation'
                  alt=''
                  className={classNames().featureIcon}
                />
                <h2>Enterprise-grade solutions</h2>
                <p>Build secure, global, scalable solutions that integrate with your existing IT ecosystem.</p>
              </div>
              <div className={`${classNames().featureCard} ms-Grid-col ${classNames().sm6} ${classNames().xl3}`}>
                <img
                  src={`${imagesRoot}/Complete_Ownership_Icon.png`}
                  role='presentation'
                  alt=''
                  className={classNames().featureIcon}
                />
                <h2>Ownership and control</h2>
                <p>Create an AI experience that can extend your brand and keep you in control of your own data.</p>
              </div>
            </div>
          </div>
        </section>
        <section id='composer' className={classNames().composerSection} aria-labelledby='Compose a bot. Today.'>
          <div>
            <div className={classNames().composerSectionImage}>
              <img
                src={`${imagesRoot}/Composer_Icon.png`}
                role='presentation'
                alt=''
              />
            </div>
            <div className={classNames().composerSectionContent}>
              <h2>Compose a bot. Today.</h2>
              <p>Bot Framework Composer is an open-source, visual authoring canvas for developers and multi-disciplinary teams to design and build conversational experiences with Language Understanding, QnA Maker, and a sophisticated composition of bot replies (Language Generation). <a href='https://docs.microsoft.com/en-us/composer/' aria-label='See bot framework composer documentation'>See documentation {'>'}</a></p>
              <div className={classNames().composerLinkDiv}>
                <a href={getComposerDownloadLink()} className={classNames().composerLink}>Download Composer</a>
              </div>
              <p>Download Bot Framework Composer for <a className={classNames().underlinedLink} href='https://aka.ms/bf-composer-download-win'>Windows</a>, <a className={classNames().underlinedLink} href='https://aka.ms/bf-composer-download-mac'>Mac</a> or <a className={classNames().underlinedLink} href='https://aka.ms/bf-composer-download-linux'>Linux</a>.</p>
            </div>
          </div>
        </section>
        <section className={classNames().imageSection} aria-labelledby='Build conversational AI experiences for your organization'>
          <div className='ms-Grid' dir='ltr'>
            <div className='ms-Grid-row'>
              <div className={`ms-Grid-col ${classNames().xl6} ms-sm12`}>
                <h2>Build conversational AI experiences for your organization</h2>
                <p>Azure Bot Service enables you to build intelligent, enterprise-grade bots with ownership and control of your data. Begin with a simple Q&A bot or build a sophisticated virtual assistant.</p>
                <p>Use comprehensive open source SDK and tools to easily connect your bot to popular channels and devices. Give your bot the ability to speak, listen, and understand your users with native integration to Azure Cognitive Services.</p>
              </div>
              <div className={`ms-Grid-col ${classNames().xl6} ms-sm12`}>
                <div className={classNames().phoneImageContainer}>
                  <img
                    src={`${imagesRoot}/Phone.png`}
                    role='presentation'
                    alt=''
                    className={classNames().phoneImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='customers' className={classNames().customersSection} aria-labelledby='Virtual Assistant'>
          <h2 className={classNames().centeredHeader}>Engage with customers in a conversational manner</h2>
          <div className='ms-Grid'>
            <div className='ms-Grid-row' dir='ltr'>
              <div className={`ms-Grid-col ${classNames().xl4} ms-sm12`}>
                <div className={classNames().customersColumn}>
                  <h3>Virtual Assistant</h3>
                  <p>Virtual Assistant allows your customers to interact with your services at home, at work and in your car. Get started in minutes and then extended using our end to end development tooling.</p>
                  <h3>Customer Care</h3>
                  <p>Continuously improve and personalize your customer support and make your service available on any channel and any device.</p>
                  <h3>Enterprise</h3>
                  <p>Empower your organization with AI, to more effectively connect people, tasks, information and services in a conversational experience.</p>
                </div>
              </div>
              <div className={`ms-Grid-col ms-Grid-col ${classNames().xl8} ms-sm12`} dir='ltr'>
                <div className={`${classNames().customerCardsRow} ms-Grid-row`}>
                  <div className={`ms-Grid-col ${classNames().xxl4} ${classNames().lg6} ms-sm12`}>
                    <CustomerCard
                      text='LaLiga boosts fan engagement across multiple channels with its own virtual assistant.'
                      imageName={`${imagesRoot}/LaLiga.png`}
                      altText='LaLiga'
                      href='https://customers.microsoft.com/en-us/story/laliga-media-entertainment-azure'
                    />
                  </div>
                  <div className={`ms-Grid-col ${classNames().xxl4} ${classNames().lg6} ms-sm12`}>
                    <CustomerCard
                      text='Spanish telco customers use digital assistant Aura to manage accounts, get real-time support, check data usage, or review billing information, all through voice interaction - in several languages.'
                      imageName={`${imagesRoot}/telefonica_logo.png`}
                      altText='Telefonica'
                      href='https://aka.ms/bot-framework-telefonica'
                    />
                  </div>
                  <div className={`ms-Grid-col ${classNames().xxl4} ${classNames().lg6} ms-sm12`}>
                    <CustomerCard
                      text={'Progressive Insurance\'s Flo Chatbot answers, provides quotes, and even offers a bit of witty banter in Flo\'s well-known style.'}
                      imageName={`${imagesRoot}/Progressive_logo.png`}
                      altText='Progressive Insurance'
                      href='https://customers.microsoft.com/en-us/story/789698-progressive-insurance-cognitive-services-insurance'
                    />
                  </div>
                  <div className={`ms-Grid-col ${classNames().xxl4} ${classNames().lg6} ms-sm12`}>
                    <CustomerCard
                      text='Adobe built a chatbot that reduces friction points as customers go through their learning journey.'
                      imageName={`${imagesRoot}/Adobe_Systems_logo.png`}
                      altText='Adobe Systems'
                      href='https://channel9.msdn.com/Shows/AI-Show/Adobe-uses-Conversational-AI-to-drive-customer-engagement?ocid=player'
                    />
                  </div>
                  <div className={`ms-Grid-col ${classNames().xxl4} ${classNames().lg6} ms-sm12`}>
                    <CustomerCard
                      text='The University of South Wales Sydney has harnessed the power of Microsoft Bot Framework to create a more engaging learning community.'
                      imageName={`${imagesRoot}/University_South_Wales_logo.png`}
                      altText='The University of South Wales Sydney'
                      href='https://customers.microsoft.com/en-us/story/759309-unsw-higher-education-azure-teams-powerbi-australia-en'
                    />
                  </div>
                  <div className={`ms-Grid-col ${classNames().xxl4} ${classNames().lg6} ms-sm12`}>
                    <CustomerCard
                      text='Jet.com servers customers faster, optimizing customer care while reducing call handling time.'
                      imageName={`${imagesRoot}/Jet_Com.png`}
                      altText='Jet.com'
                      href='https://customers.microsoft.com/en-us/story/jet-dot-com-retailers-azure'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={classNames().colorBandDivider} />
        <section className={classNames().componentsSection} aria-labelledby='Components of a conversational AI experience'>
          <div>
            <h2 className={classNames().centeredHeader}>Components of a conversational AI experience</h2>
            <img
              src={`${imagesRoot}/BotFrameworkDiagram.png`}
              alt='Diagram explaining the components of a conversation AI experience'
              className={classNames().fullWidthImage}
            />
            <div className={classNames().lineDivider} />
            <div id='cognitive-services' className='ms-Grid' dir='ltr'>
              <div className={`${classNames().flexRow} ms-Grid-row`}>
                <div className={`${classNames().componentsColumn} ms-Grid-col`}>
                  <img
                    src={`${imagesRoot}/Language_Icon.png`}
                    role='presentation'
                    alt=''
                    className={classNames().componentsImage}
                  />
                  <h2>Language</h2>
                  <p>A machine learning-based service to build natural language experiences. Quickly create enterprise-ready, custom models that continuously improve.</p>
                  <p>
                    <a href='https://luis.ai' className={classNames().learnMoreLink} aria-label='Learn more about Language'>Learn More {'>'}</a>
                  </p>
                </div>
                <div className={`${classNames().componentsColumn} ms-Grid-col`}>
                  <img
                    src={`${imagesRoot}/QnAMaker_Icon.png`}
                    role='presentation'
                    alt=''
                    className={classNames().componentsImage}
                  />
                  <h2>QnA Maker</h2>
                  <p>Publish a simple question and answer bot based on existing FAQ URLs, structured documents and product manuals.</p>
                  <p>
                    <a href='https://www.qnamaker.ai/' className={classNames().learnMoreLink} aria-label='Learn more about Q and A Maker'>Learn More {'>'}</a>
                  </p>
                </div>
                <div className={`${classNames().componentsColumn} ms-Grid-col`}>
                  <img
                    src={`${imagesRoot}/Speech_Icon.png`}
                    role='presentation'
                    alt=''
                    className={classNames().componentsImage}
                  />
                  <h2>Speech</h2>
                  <p>Hear commands, identify individual speakers, translate, and reply to customers in natural language with a branded voice.</p>
                  <p>
                    <a href='https://azure.microsoft.com/en-us/services/cognitive-services/directory/speech/' className={classNames().learnMoreLink} aria-label='Learn more about Speech'>Learn More {'>'}</a>
                  </p>
                </div>
                <div className={`${classNames().componentsColumn} ms-Grid-col`}>
                  <img
                    src={`${imagesRoot}/Search_Icon.png`}
                    role='presentation'
                    alt=''
                    className={classNames().componentsImage}
                  />
                  <h2>Search</h2>
                  <p>Search across defined domains or the web for customer data, inventory status, research results, transaction records and more.</p>
                  <p>
                    <a href='https://azure.microsoft.com/en-us/services/cognitive-services/directory/search/' className={classNames().learnMoreLink} aria-label='Learn more about Search'>Learn More {'>'}</a>
                  </p>
                </div>
                <div className={`${classNames().componentsColumn} ms-Grid-col`}>
                  <img
                    src={`${imagesRoot}/Vision_Icon.png`}
                    role='presentation'
                    alt=''
                    className={classNames().componentsImage}
                  />
                  <h2>Vision</h2>
                  <p>Recognize faces, moderate content, and index images and video with computer vision services.</p>
                  <p>
                    <a href='https://azure.microsoft.com/en-us/services/cognitive-services/directory/vision/' className={classNames().learnMoreLink} aria-label='Learn more about Vision'>Learn More {'>'}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div>
            <div className={classNames().quoteSection}>
              <p className={classNames().quoteParagraph}>"Adobe sees Conversational AI as foundational to providing the personalized experiences for each of our users. Bot Framework is extremely flexible. It lets us use our own backend services. We can typically go from an idea for a bot to having a working prototype in just a matter of days."</p>
              <div className={classNames().quoteByLine}>
                <img src={`${imagesRoot}/Adobe_Systems_logo.png`} alt='Adobe' />
                <div>
                  <p>Jacob Hammons, Principal Product Manager</p>
                  <p>Adobe</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* tslint:disable-next-line */}
        <section id='life-cycle' aria-labelledby="Bot's life cycle">
          <div>
            <h2 className={classNames().centeredHeader}>Bot's life cycle</h2>
            <img
              src={`${imagesRoot}/BotLifeCycle.png`}
              alt='Design timeline of a bot'
              className={classNames().lifeCycleImage}
            />
            <div className='ms-Grid' dir='ltr'>
              <div className={`${classNames().flexRow} ms-Grid-row`}>
                <div className={`${classNames().lifecycleColumn} ms-Grid-col ${classNames().xl2} ${classNames().lg4} ${classNames().sm6}`}>
                  <h2 id='design-links'>Design</h2>
                  <ul>
                    <li><a href='https://aka.ms/bot-framework-emulator-design-guidelines' id='design-link-1' aria-labelledby='design-links design-link-1'>Design guidelines</a></li>
                    <li><a href='https://docs.microsoft.com/en-us/azure/bot-service/bot-service-quickstart' id='design-link-2' aria-labelledby='design-links design-link-2'>Quick start with Azure</a></li>
                    <li><a href='https://docs.microsoft.com/en-us/azure/bot-service/dotnet/bot-builder-dotnet-sdk-quickstart' id='design-link-3' aria-label='Quick start with Dot Net SDK' aria-labelledby='design-links design-link-3'>Quick start with .NET SDK</a></li>
                    <li><a href='https://docs.microsoft.com/en-us/azure/bot-service/javascript/bot-builder-javascript-quickstart' aria-label='Quick start with Node dot JS' id='design-link-4' aria-labelledby='design-links design-link-4'>Quick start with Node.JS</a></li>
                    <li><a href='https://docs.microsoft.com/en-us/azure/bot-service/bot-builder-tutorial-basic-deploy' id='design-link-5' aria-labelledby='design-links design-link-5'>Tutorials</a></li>
                  </ul>
                </div>
                <div className={`${classNames().lifecycleColumn} ms-Grid-col ${classNames().xl2} ${classNames().lg4} ${classNames().sm6}`}>
                  <h2 id='build-links'>Build</h2>
                  <ul>
                    <li><a href='https://github.com/Microsoft/BotBuilder-Samples/tree/master/samples/csharp_dotnetcore' id='build-link-1' aria-label='C Sharp Dot Net Samples' aria-labelledby='build-links build-link-1'>C#.NET Samples</a></li>
                    <li><a href='https://github.com/Microsoft/BotBuilder-Samples/tree/master/samples/javascript_nodejs' id='build-link-2' aria-label='Node dot JS Samples' aria-labelledby='build-links build-link-2'>Node.js Samples</a></li>
                    <li><a href='https://azure.microsoft.com/en-us/services/cognitive-services/' id='build-link-3' aria-labelledby='build-links build-link-3'>Cognitive Services</a></li>
                    <li><a href='https://github.com/Microsoft/AI' id='build-link-4' aria-labelledby='build-links build-link-4'>Solutions (Virtual Assistant, Customer Care, Enterprise)</a></li>
                  </ul>
                </div>
                <div className={`${classNames().lifecycleColumn} ms-Grid-col ${classNames().xl2} ${classNames().lg4} ${classNames().sm6}`}>
                  <h2 id='test-links'>Test</h2>
                  <ul>
                    <li><a href='https://github.com/Microsoft/BotFramework-Emulator/releases/latest' id='test-link-1' aria-labelledby='test-links test-link-1'>Test in Emulator</a></li>
                    <li><a href='https://github.com/Microsoft/BotFramework-WebChat/releases/latest' id='test-link-2' aria-labelledby='test-links test-link-2'>Test in Web Chat</a></li>
                  </ul>
                </div>
                <div className={`${classNames().lifecycleColumn} ms-Grid-col ${classNames().xl2} ${classNames().lg4} ${classNames().sm6}`}>
                  <h2 id='publish-links'>Publish</h2>
                  <ul>
                    <li><a href='https://aka.ms/bot-framework-emulator-publish-Azure' id='publish-link-1' aria-labelledby='publish-links publish-link-1'>Publish directly to Azure</a></li>
                    <li><a href='https://aka.ms/bot-framework-emulator-publish-continuous-deployment' id='publish-link-2' aria-labelledby='publish-links publish-link-2'>Use Continuous Deployment</a></li>
                  </ul>
                </div>
                <div className={`${classNames().lifecycleColumn} ms-Grid-col ${classNames().xl2} ${classNames().lg4} ${classNames().sm6}`}>
                  <h2 id='connect-links'>Connect</h2>
                  <ul>
                    <li><a href='https://aka.ms/bot-framework-emulator-connect-channels' id='connect-link-1' aria-labelledby='connect-links connect-link-1'>Connect to Channels</a></li>
                  </ul>
                </div>
                <div className={`${classNames().lifecycleColumn} ms-Grid-col ${classNames().xl2} ${classNames().lg4} ${classNames().sm6}`}>
                  <h2 id='evaluate-links'>Evaluate it</h2>
                  <ul>
                    <li><a href='https://aka.ms/bot-framework-emulator-bot-analytics' id='evaluate-link-1' aria-labelledby='evaluate-links evaluate-link-1'>View Analytics</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={classNames().colorBandDivider} />
        <section id='quick-starts' className={classNames().quickStartSection}>
          <div>
            <h2 className={classNames().centeredHeader}>5-minute quick start to create your bot</h2>
            <div className={classNames().quickstartRow}>
              <QuickStart
                text='Azure'
                imageUrl={`${imagesRoot}/ABS_Icon.png`}
                href='https://aka.ms/bot-framework-emulator-create-bot-azure'
                ariaLabel='5-minute quick start to create your bot in Azure'
              />
              <QuickStart
                text='C#'
                imageUrl={`${imagesRoot}/CSharp_Icon.png`}
                href='https://learn.microsoft.com/en-us/azure/bot-service/bot-service-quickstart-create-bot?view=azure-bot-service-4.0&branch=Ignite2018&tabs=csharp%2Cvs#prerequisites'
                ariaLabel='5-minute quick start to create your bot in C#'
              />
              <QuickStart
                text='Node.js'
                imageUrl={`${imagesRoot}/JSnode_Icon.png`}
                href='https://learn.microsoft.com/en-us/azure/bot-service/bot-service-quickstart-create-bot?view=azure-bot-service-4.0&tabs=javascript%2Cvs#prerequisites'
                ariaLabel='5-minute quick start to create your bot in Node.js'
              />
            </div>
            <p className={classNames().azureDisclaimer}>
              *Your Azure free account includes free access to the most popular Azure products for 12 months, $200 credit to explore any Azure service in your first 30 days, and access to more than 25 products are always free.
            </p>
          </div>
        </section>
        <section className={classNames().footerSection}>
          <div className='ms-Grid' dir='ltr'>
            <div className='ms-Grid-row'>
              <div className={`${classNames().footerColumn} ms-Grid-col ${classNames().lg4} ms-sm12`}>
                <h2>Resources</h2>
                <ul>
                  <li>
                    <a href='https://github.com/Microsoft/BotBuilder/'>BotBuilder SDK Github</a>
                  </li>
                  <li>
                    <a href='https://portal.azure.com/#blade/Microsoft_Azure_Health/AzureHealthBrowseBlade'>Azure status</a>
                  </li>
                  <li>
                    <a href='https://azure.microsoft.com/en-us/pricing/details/bot-service/'>Pricing</a>
                  </li>
                  <li>
                    <a href='https://aka.ms/azure/bots/overview/docs'>Documentation</a>
                  </li>
                </ul>
              </div>
              <div className={`${classNames().footerColumn} ms-Grid-col ${classNames().lg4} ms-sm12`}>
                <h2>Support</h2>
                <ul>
                  <li>
                    <a href='https://github.com/Microsoft/BotBuilder/issues'>BotBuilder SDK Support</a>
                  </li>
                  <li>
                    <a href='https://stackoverflow.com/questions/tagged/botframework'>Stack Overflow</a>
                  </li>
                  <li>
                    <a href='https://docs.microsoft.com/en-us/azure/bot-service/bot-service-resources-links-help'>Feedback and Support</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={classNames().copyrightSection}>
              <a href='https://aka.ms/bf-contactus'>Contact us</a>
              <a onClick={handleCookieConsentDialog} role='button' >Manage Cookies</a>
              <a href='https://aka.ms/bf-privacy'>Privacy & cookies</a>
              <a href='https://aka.ms/bf-terms'>Terms of use</a>
              <span>© 2019 Microsoft</span>
            </div>
          </div>
        </section>
      </div>
    </Fabric>
  );
};

function handleCookieConsentDialog() {
  window.siteConsent.manageConsent();
}

function getComposerDownloadLink() {
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'iPhone', 'iPad', 'iPod'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os = 'win'; // default to windows
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'mac';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'win';
  } else if (/Linux/.test(platform)) {
    os = 'linux';
  }

  return `https://aka.ms/bf-composer-download-${os}`;
}
