// This file was automatically generated from DevPortalLib.dll. Do not edit directly!
// tslint:disable

import "whatwg-fetch";
import {formatQuery} from "@fuselab/ui-shared";
type AjaxSettings = Request;
type AjaxPromise<T> = Promise<T>;
const innerAjax = fetch;
const extend = Object.assign;
    /** Optionally, set this variable to your network activity indicator function. */
    export var showNetworkIcon = (show: boolean) => { };

    /** Optionally, set this to your own ajax function. */
    export var ajax = _ajax;

    /** Optionally, set this to a string that all urls should be prefixed with. */
    export var urlPrefix = '';

    /** Optionally, set this to additional header key/value pairs to send along with ajax requests. */
    export var ajaxHeaders: { [key: string]: any } = { 'content-type': 'application/json' };

    /** Wraps ajax function and adds network activity indicator. */
    function _ajax(settings_: {dataType?: 'json', type: 'GET'|'POST'|'PUT'|'PATCH'|'DEL'|'DELETE', data?: any, url: string}): AjaxPromise<any> {
        const url = urlPrefix + settings_.url;
        const request : RequestInit = {
            method: settings_.type,
            body: settings_.data,
            headers: ajaxHeaders,
            credentials: 'include'
        };
        showNetworkIcon(true);
        return innerAjax(url, request)
       .then(response => {
           if (!response.ok) {
               throw response;
           } else {
             if (response.status == 204) {
                return Promise.resolve(null);
             } else {
               if (response.headers.get('content-length') === '0') {
                 return null;
               }
               return response.json();
             }
           }
        })
       .then(x => {showNetworkIcon(false); return Promise.resolve(x);})
       .catch(e => {showNetworkIcon(false); return Promise.reject(e);});
    }

    /** Corresponds to Microsoft.Portal.Extensions.AzurePortalExtension1.Controllers.ConnectionSettingsController */
    export class ConnectionSettingsClient {
        /** Corresponds to GetServiceProviders(string botId) */
        public static getServiceProviders(botId: string): AjaxPromise<ServiceProvider[]> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/GetServiceProviders' + '?' + formatQuery({ botId: botId }),
                type: 'GET'
            });
        }

        /** Corresponds to GetConnectionSettingItems(string botId) */
        public static getConnectionSettingItems(botId: string): AjaxPromise<ConnectionSettingItem[]> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/GetConnectionSettingItems' + '?' + formatQuery({ botId: botId }),
                type: 'GET'
            });
        }

        /** Corresponds to GetFullConnectionSettingEx(string id, string botId) */
        public static getFullConnectionSettingEx(id: string, botId: string): AjaxPromise<ConnectionSetting> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/GetFullConnectionSettingEx' + '?' + formatQuery({ id: id, botId: botId }),
                type: 'POST'
            });
        }

        /** Corresponds to CheckConnectionSettingName(string botId, string name) */
        public static checkConnectionSettingName(botId: string, name: string): AjaxPromise<CheckNameExistsResult> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/CheckConnectionSettingName' + '?' + formatQuery({ botId: botId, name: name }),
                type: 'POST'
            });
        }

        /** Corresponds to AddConnection(string botId, ConnectionSetting properties) */
        public static addConnection(botId: string, properties: _ConnectionSetting): AjaxPromise<ConnectionSetting> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/AddConnection' + '?' + formatQuery({ botId: botId }),
                data: JSON.stringify(properties),
                type: 'POST'
            });
        }

        /** Corresponds to UpdateConnectionEx(string id, string botId, ConnectionSetting properties) */
        public static updateConnectionEx(id: string, botId: string, properties: _ConnectionSetting): AjaxPromise<ConnectionSetting> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/UpdateConnectionEx' + '?' + formatQuery({ id: id, botId: botId }),
                data: JSON.stringify(properties),
                type: 'POST'
            });
        }

        /** Corresponds to DeleteConnectionEx(string id, string botId) */
        public static deleteConnectionEx(id: string, botId: string): AjaxPromise<boolean> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/DeleteConnectionEx' + '?' + formatQuery({ id: id, botId: botId }),
                type: 'POST'
            });
        }

        /** Corresponds to GetTestConnectionUrl(string botId, string connectionName) */
        public static getTestConnectionUrl(botId: string, connectionName: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/connectionsettings/GetTestConnectionUrl' + '?' + formatQuery({ botId: botId, connectionName: connectionName }),
                type: 'GET'
            });
        }
    }

    /** Corresponds to Intercom.BotDirectory.Server.Controllers.HomeController */
    export class HomeClient {
        /** Corresponds to Index() */
        public static index(): AjaxPromise<any> {
            return ajax({
                url: '/Home',
                type: 'GET'
            });
        }

        /** Corresponds to WarmUp() */
        public static warmUp(): AjaxPromise<any> {
            return ajax({
                url: '/Home/WarmUp',
                type: 'GET'
            });
        }
    }

    /** Corresponds to Intercom.DevPortal.Server.Controllers.AccountController */
    export class AccountClient {
        /** Corresponds to GetClientSettings() */
        public static getClientSettings(): AjaxPromise<ClientAppSettings> {
            return ajax({
                dataType: 'json',
                url: '/api/Account/clientAppSettings',
                type: 'GET'
            });
        }

        /** Corresponds to Delete() */
        public static delete(): AjaxPromise<any> {
            return ajax({
                url: '/api/Account',
                type: 'DELETE'
            });
        }

        /** Corresponds to AddUserToWaitlist() */
        public static addUserToWaitlist(): AjaxPromise<any> {
            return ajax({
                url: '/api/Account/waitList',
                type: 'POST'
            });
        }

        /** Corresponds to ExchangeGraphToken(string tenantId) */
        public static exchangeGraphToken(tenantId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/Account/exchangeGraphToken' + '?' + formatQuery({ tenantId: tenantId }),
                type: 'POST'
            });
        }

        /** Corresponds to ExchangeArmToken(string tenantId) */
        public static exchangeArmToken(tenantId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/Account/exchangeArmToken' + '?' + formatQuery({ tenantId: tenantId }),
                type: 'POST'
            });
        }

        /** Corresponds to GetSignedInUser() */
        public static getSignedInUser(): AjaxPromise<AuthData> {
            return ajax({
                dataType: 'json',
                url: '/api/Account',
                type: 'GET'
            });
        }
    }

    /** Corresponds to Intercom.DevPortal.Server.Controllers.AnnouncementsController */
    export class AnnouncementsClient {
        /** Corresponds to Announcements() */
        public static announcements(): AjaxPromise<Announcement[]> {
            return ajax({
                dataType: 'json',
                url: '/api/announcements',
                type: 'GET'
            });
        }
    }

    /** Corresponds to Intercom.DevPortal.Server.Controllers.BotManagerController */
    export class BotManagerClient {
        /** Corresponds to GetPage(int pageSize, string continuationToken) */
        public static getPage(pageSize: number, continuationToken: string): AjaxPromise<SearchResult<BotDocument>> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/getPage' + '?' + formatQuery({ pageSize: pageSize, continuationToken: continuationToken }),
                type: 'GET'
            });
        }

        /** Corresponds to SearchBotsPage(string search, int pageSize, string continuationToken) */
        public static searchBotsPage(search: string, pageSize: number, continuationToken: string): AjaxPromise<SearchResult<BotDocument>> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/searchBotsPage' + '?' + formatQuery({ search: search, pageSize: pageSize, continuationToken: continuationToken }),
                type: 'GET'
            });
        }

        /** Corresponds to Get(string id) */
        public static getById(id: string): AjaxPromise<BotSubscription> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/Bots' + '?' + formatQuery({ id: id }),
                type: 'GET'
            });
        }

        /** Corresponds to GetWebChatToken(string id) */
        public static getWebChatToken(id: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${id}/webChatToken`,
                type: 'GET'
            });
        }

        /** Corresponds to Create(BotService bot) */
        public static create(bot: _BotService): AjaxPromise<BotService> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager',
                data: JSON.stringify(bot),
                type: 'POST'
            });
        }

        /** Corresponds to EnableChannelForBot(string botId, string channelId, Boolean enable) */
        public static enableChannelForBot(botId: string, channelId: string, enable: boolean): AjaxPromise<BotService> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/EnableChannelForBot' + '?' + formatQuery({ botId: botId, channelId: channelId, enable: enable }),
                type: 'POST'
            });
        }

        /** Corresponds to ListChannelForBot(string botId, string channelId, Boolean listed) */
        public static listChannelForBot(botId: string, channelId: string, listed: boolean): AjaxPromise<BotService> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/ListChannelForBot' + '?' + formatQuery({ botId: botId, channelId: channelId, listed: listed }),
                type: 'POST'
            });
        }

        /** Corresponds to Update(BotService bot, Boolean forPublishing) */
        public static update(bot: _BotService, forPublishing: boolean): AjaxPromise<BotService> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager' + '?' + formatQuery({ forPublishing: forPublishing }),
                data: JSON.stringify(bot),
                type: 'PUT'
            });
        }

        /** Corresponds to GetBotLogs(string botId) */
        public static getBotLogs(botId: string): AjaxPromise<BotLog[]> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${botId}/log`,
                type: 'GET'
            });
        }

        /** Corresponds to DeleteBotLogs(string botId, string channelId) */
        public static deleteBotLogs(botId: string, channelId: string): AjaxPromise<any> {
            return ajax({
                url: `/api/BotManager/${botId}/log/${channelId}`,
                type: 'DELETE'
            });
        }

        /** Corresponds to GetPublishSettings(string botId) */
        public static getPublishSettings(botId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${botId}/publishSettings`,
                type: 'GET'
            });
        }

        /** Corresponds to GetConfigSettings(string botId) */
        public static getConfigSettings(botId: string): AjaxPromise<AzureResource<AppConfigProperties>> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${botId}/configSettings`,
                type: 'GET'
            });
        }

        /** Corresponds to GetProgressAsync(string botId, Boolean checkingMigration) */
        public static getProgress(botId: string, checkingMigration: boolean): AjaxPromise<BotService> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${botId}/progress` + '?' + formatQuery({ checkingMigration: checkingMigration }),
                type: 'GET'
            });
        }

        /** Corresponds to Delete(string botId) */
        public static delete(botId: string): AjaxPromise<any> {
            return ajax({
                url: '/api/BotManager' + '?' + formatQuery({ botId: botId }),
                type: 'DELETE'
            });
        }

        /** Corresponds to DeleteChannelConfig(string botId, string channelId) */
        public static deleteChannelConfig(botId: string, channelId: string): AjaxPromise<any> {
            return ajax({
                url: `/api/BotManager/${botId}/channels/${channelId}`,
                type: 'DELETE'
            });
        }

        /** Corresponds to EnsureDirectlineSettings(string botId) */
        public static ensureDirectlineSettings(botId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${botId}/directlineSettings`,
                type: 'POST'
            });
        }

        /** Corresponds to AddPublishEvent(string botId, PublishEvent publishEvent, string comment, Boolean showInAllDirectories, string channel) */
        public static addPublishEvent(botId: string, publishEvent: PublishEvent, comment: string, showInAllDirectories: boolean, channel: string): AjaxPromise<BotHistory> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/UpdatePublishStatus' + '?' + formatQuery({ botId: botId, publishEvent: publishEvent, comment: comment, showInAllDirectories: showInAllDirectories, channel: channel }),
                type: 'POST'
            });
        }

        /** Corresponds to GetLastPublishEvent(string botId, string channel) */
        public static getLastPublishEvent(botId: string, channel: string): AjaxPromise<BotHistory> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/GetLastPublishEvent' + '?' + formatQuery({ botId: botId, channel: channel }),
                type: 'GET'
            });
        }

        /** Corresponds to GetPublishHistory(string botId, string channel) */
        public static getPublishHistory(botId: string, channel: string): AjaxPromise<BotHistory[]> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/GetPublishHistory' + '?' + formatQuery({ botId: botId, channel: channel }),
                type: 'GET'
            });
        }

        /** Corresponds to GetAvailableChannels() */
        public static getAvailableChannels(): AjaxPromise<Channel[]> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/AvailableChannels',
                type: 'GET'
            });
        }

        /** Corresponds to RegenerateSubscriptionKey(string botId, Boolean primary) */
        public static regenerateSubscriptionKey(botId: string, primary: boolean): AjaxPromise<BotSubscription> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/RegenerateSubscriptionKey' + '?' + formatQuery({ botId: botId, primary: primary }),
                type: 'POST'
            });
        }

        /** Corresponds to GetBotContactCard(string botId) */
        public static getBotContactCard(botId: string): AjaxPromise<BotContactCard> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/GetBotContactCard' + '?' + formatQuery({ botId: botId }),
                type: 'POST'
            });
        }

        /** Corresponds to TestBot(string botId, string testInput) */
        public static testBot(botId: string, testInput: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/TestBot' + '?' + formatQuery({ botId: botId, testInput: testInput }),
                type: 'POST'
            });
        }

        /** Corresponds to CreateLuisAppUsingKey(string luisAppName, string luisKey, LuisRegions region, string modelName) */
        public static createLuisAppUsingKey(luisAppName: string, luisKey: string, region: LuisRegions, modelName: string): AjaxPromise<LuisAppData> {
            return ajax({
                dataType: 'json',
                url: `/api/BotManager/${luisAppName}/CreateLuisAppUsingKey` + '?' + formatQuery({ luisKey: luisKey, region: region, modelName: modelName }),
                type: 'POST'
            });
        }

        /** Corresponds to SetBotIcon(string dataUrl, string botId) */
        public static setBotIcon(dataUrl: string, botId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/PostBotIcon' + '?' + formatQuery({ botId: botId }),
                data: JSON.stringify(dataUrl),
                type: 'POST'
            });
        }

        /** Corresponds to MigrateBot(string botId) */
        public static migrateBot(botId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager' + '?' + formatQuery({ botId: botId }),
                type: 'POST'
            });
        }

        /** Corresponds to GetAvailableSchemaTransformationVersionList() */
        public static getAvailableSchemaTransformationVersionList(): AjaxPromise<SchemaTransformationVersion[]> {
            return ajax({
                dataType: 'json',
                url: '/api/BotManager/getAvailableSchemaTransformationVersionList',
                type: 'GET'
            });
        }
    }

    /** Corresponds to Intercom.DevPortal.Server.Controllers.CodeController */
    export class CodeClient {
        /** Corresponds to List(string botId, string path) */
        public static list(botId: string, path: string): AjaxPromise<VfsNodeInfo[]> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/list` + '?' + formatQuery({ path: path }),
                type: 'GET'
            });
        }

        /** Corresponds to Zip(string botId, string fileName) */
        public static zip(botId: string, fileName: string): AjaxPromise<any> {
            return ajax({
                url: `/api/botApp/${botId}/zip/${fileName}`,
                type: 'GET'
            });
        }

        /** Corresponds to Get(string botId, string path) */
        public static getByBotIdAndPath(botId: string, path: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/code` + '?' + formatQuery({ path: path }),
                type: 'GET'
            });
        }

        /** Corresponds to Update(string botId, string path, string content) */
        public static update(botId: string, path: string, content: string): AjaxPromise<boolean> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/code` + '?' + formatQuery({ path: path }),
                data: JSON.stringify(content),
                type: 'POST'
            });
        }

        /** Corresponds to Create(string botId, string path, string content) */
        public static create(botId: string, path: string, content: string): AjaxPromise<boolean> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/code` + '?' + formatQuery({ path: path }),
                data: JSON.stringify(content),
                type: 'PUT'
            });
        }

        /** Corresponds to Remove(string botId, string path, string content) */
        public static remove(botId: string, path: string, content: string): AjaxPromise<boolean> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/code` + '?' + formatQuery({ path: path }),
                data: JSON.stringify(content),
                type: 'DELETE'
            });
        }

        /** Corresponds to GetScmAuthz(string botId) */
        public static getScmAuthz(botId: string): AjaxPromise<ScmAuthorization> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/scmAuthz`,
                type: 'GET'
            });
        }

        /** Corresponds to RequestDevInfo(string botId) */
        public static requestDevInfo(botId: string): AjaxPromise<BotDevInfo> {
            return ajax({
                dataType: 'json',
                url: `/api/botApp/${botId}/devInfo`,
                type: 'POST'
            });
        }

        /** Corresponds to FetchBotId(string resourceId, string accessToken) */
        public static fetchBotId(resourceId: string, accessToken: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/botApp/mapBotId' + '?' + formatQuery({ resourceId: resourceId }),
                data: JSON.stringify(accessToken),
                type: 'POST'
            });
        }

        /** Corresponds to ProvisionConvergedApp(string name) */
        public static provisionConvergedApp(name: string): AjaxPromise<ConvergedApp> {
            return ajax({
                dataType: 'json',
                url: '/api/botApp/provisionConvergedApp' + '?' + formatQuery({ name: name }),
                type: 'POST'
            });
        }

        /** Corresponds to ProvisionSingleTenantConvergedApp(string name) */
        public static provisionSingleTenantConvergedApp(name: string): AjaxPromise<ConvergedApp> {
            return ajax({
                dataType: 'json',
                url: '/api/botApp/provisionSingleTenantConvergedApp' + '?' + formatQuery({ name: name }),
                type: 'POST'
            });
        }

        /** Corresponds to CheckBotNameAvailability(string botName) */
        public static checkBotNameAvailability(botName: string): AjaxPromise<CheckNameAvailabilityResponse> {
            return ajax({
                dataType: 'json',
                url: '/api/botApp/checkBotNameAvailability' + '?' + formatQuery({ botName: botName }),
                type: 'POST'
            });
        }

        /** Corresponds to CheckFeatureEnabledAsync(string botId, string featureId) */
        public static checkFeatureEnabled(botId: string, featureId: string): AjaxPromise<CheckFeatureEnabledResponse> {
            return ajax({
                dataType: 'json',
                url: '/api/botApp/checkFeatureEnabled' + '?' + formatQuery({ botId: botId, featureId: featureId }),
                type: 'POST'
            });
        }
    }

    /** Corresponds to Intercom.DevPortal.Server.Controllers.EmailSubscriptionController */
    export class EmailSubscriptionClient {
        /** Corresponds to Get() */
        public static get(): AjaxPromise<EmailSubscriptionInfo> {
            return ajax({
                dataType: 'json',
                url: '/api/emailSubscription',
                type: 'GET'
            });
        }

        /** Corresponds to GetUserAgreement() */
        public static getUserAgreement(): AjaxPromise<UserAgreementConsentInfo> {
            return ajax({
                dataType: 'json',
                url: '/api/emailSubscription/tos',
                type: 'GET'
            });
        }

        /** Corresponds to UpdateAgreement(Boolean enabled) */
        public static updateAgreement(enabled: boolean): AjaxPromise<UserAgreementConsentInfo> {
            return ajax({
                dataType: 'json',
                url: '/api/emailSubscription/tos/update',
                data: JSON.stringify(enabled),
                type: 'POST'
            });
        }

        /** Corresponds to Subscribe(EmailSubscriptionInfo update) */
        public static subscribe(update: _EmailSubscriptionInfo): AjaxPromise<EmailSubscriptionInfo> {
            return ajax({
                dataType: 'json',
                url: '/api/emailSubscription',
                data: JSON.stringify(update),
                type: 'POST'
            });
        }

        /** Corresponds to Unsubscribe(string userId, string token) */
        public static unsubscribe(userId: string, token: string): AjaxPromise<EmailSubscriptionInfo> {
            return ajax({
                dataType: 'json',
                url: `/api/emailSubscription/unsubscribe/${userId}/${token}`,
                type: 'GET'
            });
        }
    }

    /** Corresponds to DevPortalLib.Controllers.AnalyticsController */
    export class AnalyticsClient {
        /** Corresponds to GetUsers(string botId, AnalyticsRequest analyticsRequest) */
        public static getUsers(botId: string, analyticsRequest: _AnalyticsRequest): AjaxPromise<AnalyticsNumericTimelineResponse> {
            return ajax({
                dataType: 'json',
                url: `/api/dashboard/${botId}/users`,
                data: JSON.stringify(analyticsRequest),
                type: 'POST'
            });
        }

        /** Corresponds to GetMessages(string botId, AnalyticsRequest analyticsRequest) */
        public static getMessages(botId: string, analyticsRequest: _AnalyticsRequest): AjaxPromise<AnalyticsNumericTimelineResponse> {
            return ajax({
                dataType: 'json',
                url: `/api/dashboard/${botId}/messages`,
                data: JSON.stringify(analyticsRequest),
                type: 'POST'
            });
        }

        /** Corresponds to GetUserRetention(string botId, AnalyticsRequest analyticsRequest) */
        public static getUserRetention(botId: string, analyticsRequest: _AnalyticsRequest): AjaxPromise<AnalyticsUserRetentionResponse> {
            return ajax({
                dataType: 'json',
                url: `/api/dashboard/${botId}/retention`,
                data: JSON.stringify(analyticsRequest),
                type: 'POST'
            });
        }
    }

    /** Corresponds to DevPortalLib.Controllers.MiscController */
    export class MiscClient {
        /** Corresponds to GetBotFileEncryptionKey() */
        public static getBotFileEncryptionKey(): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/misc/botFileEncryptionKey',
                type: 'GET'
            });
        }

        /** Corresponds to CheckMsaAppIdInUse(string msaAppId) */
        public static checkMsaAppIdInUse(msaAppId: string): AjaxPromise<boolean> {
            return ajax({
                dataType: 'json',
                url: '/api/misc/checkMsaAppIdInUse' + '?' + formatQuery({ msaAppId: msaAppId }),
                type: 'GET'
            });
        }

        /** Corresponds to GetIsMSAApp(string appId) */
        public static getIsMSAApp(appId: string): AjaxPromise<boolean> {
            return ajax({
                dataType: 'json',
                url: '/api/misc/isMSAApp' + '?' + formatQuery({ appId: appId }),
                type: 'GET'
            });
        }

        /** Corresponds to GetBotTemplatesRoot() */
        public static getBotTemplatesRoot(): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/misc/botTemplateRoot',
                type: 'GET'
            });
        }

        /** Corresponds to AbsWebAspSku(string subscriptionId) */
        public static absWebAspSku(subscriptionId: string): AjaxPromise<string> {
            return ajax({
                dataType: 'json',
                url: '/api/misc/absWebAspSku' + '?' + formatQuery({ subscriptionId: subscriptionId }),
                type: 'GET'
            });
        }
    }

    /** Corresponds to DevPortalLib.Controllers.SearchAssistantBotV2Controller */
    export class SearchAssistantBotV2Client {
        /** Corresponds to EnableAsync(string botId, Boolean enable) */
        public static enable(botId: string, enable: boolean): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}/enable`,
                data: JSON.stringify(enable),
                type: 'POST'
            });
        }

        /** Corresponds to DeleteAsync(string botId) */
        public static delete(botId: string): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}`,
                type: 'DELETE'
            });
        }

        /** Corresponds to GetAsync(string botId) */
        public static get(botId: string): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}`,
                type: 'GET'
            });
        }

        /** Corresponds to GetUsers(string botId, string userFilter) */
        public static getUsers(botId: string, userFilter: string): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}/deploymentv2/userSuggestions` + '?' + formatQuery({ userFilter: userFilter }),
                type: 'GET'
            });
        }

        /** Corresponds to GetUserInfoByEmail(string botId, string email) */
        public static getUserInfoByEmail(botId: string, email: string): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}/deploymentv2/userInfo` + '?' + formatQuery({ email: email }),
                type: 'GET'
            });
        }

        /** Corresponds to GetUserInfoById(string botId, string id) */
        public static getUserInfoById(botId: string, id: string): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}/deploymentv2/userInfoById` + '?' + formatQuery({ id: id }),
                type: 'GET'
            });
        }

        /** Corresponds to CreateDeployment(string botId, AnswerManifest manifest) */
        public static createDeployment(botId: string, manifest: _AnswerManifest): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}/deploymentv2`,
                data: JSON.stringify(manifest),
                type: 'POST'
            });
        }

        /** Corresponds to UpdateDeployment(string botId, AnswerManifest manifest) */
        public static updateDeployment(botId: string, manifest: _AnswerManifest): AjaxPromise<any> {
            return ajax({
                url: `/api/searchAssistantBotV2/${botId}/deploymentv2`,
                data: JSON.stringify(manifest),
                type: 'PUT'
            });
        }
    }

    /** Corresponds to DevPortalLib.Controllers.SpeechPartnersController */
    export class SpeechPartnersClient {
        /** Corresponds to GetBotForSpeech(string id) */
        public static getBotForSpeech(id: string): AjaxPromise<any> {
            return ajax({
                url: '/api/partners/speech/bot' + '?' + formatQuery({ id: id }),
                type: 'GET'
            });
        }
    }


    /** Corresponds to ServiceProvider, optional properties */
    export interface _ServiceProvider {
        Id?: string;
        DisplayName?: string;
        ServiceProviderName?: string;
        DevPortalUrl?: string;
        IconUrl?: Uri;
        Parameters?: any;
    }

    /** Corresponds to ServiceProvider */
    export interface ServiceProvider {
        Id: string;
        DisplayName: string;
        ServiceProviderName: string;
        DevPortalUrl: string;
        IconUrl: Uri;
        Parameters: any;
    }

    /** Corresponds to ConnectionSettingItem, optional properties */
    export interface _ConnectionSettingItem extends _ConnectionSettingInfo {
        ServiceProviderDisplayName?: string;
    }

    /** Corresponds to ConnectionSettingItem */
    export interface ConnectionSettingItem extends ConnectionSettingInfo {
        ServiceProviderDisplayName: string;
    }

    /** Corresponds to ConnectionSetting, optional properties */
    export interface _ConnectionSetting extends _ConnectionSettingInfo {
        ClientId?: string;
        ClientSecret?: string;
        Scopes?: string;
        Parameters?: any;
    }

    /** Corresponds to ConnectionSetting */
    export interface ConnectionSetting extends ConnectionSettingInfo {
        ClientId: string;
        ClientSecret: string;
        Scopes: string;
        Parameters: any;
    }

    /** Corresponds to CheckNameExistsResult, optional properties */
    export interface _CheckNameExistsResult {
        Exists?: boolean;
        Message?: string;
    }

    /** Corresponds to CheckNameExistsResult */
    export interface CheckNameExistsResult {
        Exists: boolean;
        Message: string;
    }

    /** Corresponds to ClientAppSettings, optional properties */
    export interface _ClientAppSettings {
        applicationPath?: string;
        productName?: string;
        environment?: string;
        documentationUrl?: string;
        homeUrl?: string;
        channels?: ChannelViewModel[];
        defaultIconUrl?: string;
        cdnEndpoint?: string;
        msaPortalEndpoint?: string;
        siteVersion?: number;
        directLineUrl?: string;
        webChatUrl?: string;
        flightedFeatures?: string[];
        azurePortalEndpoint?: string;
        luisApiHostName?: string;
        appId?: string;
        enabledFeatures?: DevPortalFeatureFlags;
    }

    /** Corresponds to ClientAppSettings */
    export interface ClientAppSettings {
        applicationPath: string;
        productName: string;
        environment: string;
        documentationUrl: string;
        homeUrl: string;
        channels: ChannelViewModel[];
        defaultIconUrl: string;
        cdnEndpoint: string;
        msaPortalEndpoint: string;
        siteVersion: number;
        directLineUrl: string;
        webChatUrl: string;
        flightedFeatures: string[];
        azurePortalEndpoint: string;
        luisApiHostName: string;
        appId: string;
        enabledFeatures: DevPortalFeatureFlags;
    }

    /** Corresponds to AuthData, optional properties */
    export interface _AuthData {
        UserId?: string;
        ApplicationUserId?: string;
        EmailAddress?: string;
        FirstName?: string;
        LastName?: string;
        Roles?: string[];
        MobilePhone?: string;
        Puid?: string;
        AzureToken?: string;
        IdentityProvider?: string;
        AzureTokenExpireAt?: number;
        ScmUserName?: string;
        ScmPassword?: string;
        FlightedFeatures?: string[];
        TenantId?: string;
        Oid?: string;
        SelfToken?: string;
        AppId?: string;
        Sub?: string;
        AltSecId?: string;
    }

    /** Corresponds to AuthData */
    export interface AuthData {
        UserId: string;
        ApplicationUserId: string;
        EmailAddress: string;
        FirstName: string;
        LastName: string;
        Roles: string[];
        MobilePhone: string;
        Puid: string;
        AzureToken: string;
        IdentityProvider: string;
        AzureTokenExpireAt: number;
        ScmUserName: string;
        ScmPassword: string;
        FlightedFeatures: string[];
        TenantId: string;
        Oid: string;
        SelfToken: string;
        AppId: string;
        Sub: string;
        AltSecId: string;
    }

    /** Corresponds to Announcement, optional properties */
    export interface _Announcement extends _ETagObject {
        Id?: string;
        Author?: string;
        Severity?: AnnouncementSeverity;
        ProductArea?: string;
        Text?: string;
        LinkUrl?: string;
        ExpirationDateUTC?: Date;
    }

    /** Corresponds to Announcement */
    export interface Announcement extends ETagObject {
        Id: string;
        Author: string;
        Severity: AnnouncementSeverity;
        ProductArea: string;
        Text: string;
        LinkUrl: string;
        ExpirationDateUTC: Date;
    }

    /** Corresponds to BotDocument, optional properties */
    export interface _BotDocument {
        Id?: string;
        Name?: string;
        Description?: string;
        OwnerIds?: string[];
        Hashtags?: string[];
        Publisher?: string;
        PublisherEmail?: string;
        Languages?: string[];
        IconUrl?: string;
        DefaultIconColorHash?: string;
        ConfiguredChannels?: string[];
        EnabledChannels?: string[];
        ListedChannels?: string[];
        Listed?: boolean;
        ApprovedForBotDirectory?: boolean;
        Submitted?: boolean;
        SystemHashtags?: string[];
        ChannelAddresses?: string[];
        SortName?: string;
        CreatedDate?: DateTimeOffset;
        LastSavedDate?: DateTimeOffset;
        MigratedDate?: DateTimeOffset;
        FeaturedDisplayOrder?: number;
        ActivityRank?: number;
        CreatorId?: string;
        MsaAppId?: string;
        MsaAppTenantId?: string;
        MsaAppType?: BotAppType;
        MsaAppMSIResourceId?: string;
        Website?: string;
        Contacts?: string;
        DeveloperBotPrivacyUrl?: string;
        DeveloperBotTermsOfUseUrl?: string;
        SubscriptionId?: string;
        AzureTags?: string;
        ResourceGroupName?: string;
        ServicePlan?: string;
        Kind?: string;
        ETag?: string;
        Location?: string;
        ProvisioningState?: string;
        Endpoints?: string;
        OpenWithHint?: string;
        AppPasswordHint?: string;
        SchemaTransformationVersion?: SchemaTransformationVersion;
        IsIsolated?: boolean;
    }

    /** Corresponds to BotDocument */
    export interface BotDocument {
        Id: string;
        Name: string;
        Description: string;
        OwnerIds: string[];
        Hashtags: string[];
        Publisher: string;
        PublisherEmail: string;
        Languages: string[];
        IconUrl: string;
        DefaultIconColorHash: string;
        ConfiguredChannels: string[];
        EnabledChannels: string[];
        ListedChannels: string[];
        Listed: boolean;
        ApprovedForBotDirectory: boolean;
        Submitted: boolean;
        SystemHashtags: string[];
        ChannelAddresses: string[];
        SortName: string;
        CreatedDate: DateTimeOffset;
        LastSavedDate: DateTimeOffset;
        MigratedDate: DateTimeOffset;
        FeaturedDisplayOrder: number;
        ActivityRank: number;
        CreatorId: string;
        MsaAppId: string;
        MsaAppTenantId: string;
        MsaAppType: BotAppType;
        MsaAppMSIResourceId: string;
        Website: string;
        Contacts: string;
        DeveloperBotPrivacyUrl: string;
        DeveloperBotTermsOfUseUrl: string;
        SubscriptionId: string;
        AzureTags: string;
        ResourceGroupName: string;
        ServicePlan: string;
        Kind: string;
        ETag: string;
        Location: string;
        ProvisioningState: string;
        Endpoints: string;
        OpenWithHint: string;
        AppPasswordHint: string;
        SchemaTransformationVersion: SchemaTransformationVersion;
        IsIsolated: boolean;
    }

    /** Corresponds to DevPortalLib.Models.SearchResult<D>, optional properties */
    export interface _SearchResult<D> {
        Items?: D[];
        HasMore?: boolean;
        NextSkip?: number;
        ContinuationToken?: string;
        TotalCount?: number;
    }

    /** Corresponds to DevPortalLib.Models.SearchResult<D> */
    export interface SearchResult<D> {
        Items: D[];
        HasMore: boolean;
        NextSkip: number;
        ContinuationToken: string;
        TotalCount: number;
    }

    /** Corresponds to BotSubscription, optional properties */
    export interface _BotSubscription {
        Bot?: BotService;
        PrimarySubscriptionKey?: string;
        SecondarySubscriptionKey?: string;
        ReviewStatusDetail?: ReviewStatusDetail;
        TenantAccess?: TenantAccess;
        ReviewStatuses?: any;
        CanWriteToBot?: boolean;
    }

    /** Corresponds to BotSubscription */
    export interface BotSubscription {
        Bot: BotService;
        PrimarySubscriptionKey: string;
        SecondarySubscriptionKey: string;
        ReviewStatusDetail: ReviewStatusDetail;
        TenantAccess: TenantAccess;
        ReviewStatuses: any;
        CanWriteToBot: boolean;
    }

    /** Corresponds to BotService, optional properties */
    export interface _BotService extends _BotServiceBase {
        Name?: string;
        ProvisionInfo?: AzureProvisionInfo;
        CreatedDate?: DateTimeOffset;
        LastSavedDate?: DateTimeOffset;
        OwnerIds?: string[];
        Hashtags?: string[];
        Publisher?: string;
        PublisherEmail?: string;
        Website?: string;
        Endpoints?: Endpoint[];
        IconUrl?: string;
        Description?: string;
        ListedChannels?: string[];
        ListedMarkets?: string[];
        Listed?: boolean;
        Submitted?: boolean;
        DefaultIconColorHash?: string;
        Contacts?: ContactInfo[];
        FeaturedDisplayOrder?: number;
        DeveloperAppInsightsAPIKey?: string;
        DeveloperAppInsightsApplicationId?: string;
        DeveloperBotPrivacyUrl?: string;
        DeveloperBotTermsOfUseUrl?: string;
        CortanaProperties?: CortanaProperties;
        ApsProperties?: ApsProperties;
        ManifestUrl?: string;
        Actions?: Action[];
        LUISAppIds?: string[];
        LUISKey?: string;
        BotStateTable?: string;
        BotStatePriorTable?: string;
        BotServiceRuntimeVersion?: string;
        AzureTags?: string;
        AcceptedTermsOfService?: string[];
        MigratedDate?: DateTimeOffset;
        ProvisioningState?: string;
        IsStateStoreProhibited?: boolean;
        Languages?: string[];
        Roles?: string[];
        OpenWithHint?: string;
        AppPasswordHint?: string;
        MsaAppType?: BotAppType;
        MsaAppMSIResourceId?: string;
        ApprovedForBotDirectory?: boolean;
    }

    /** Corresponds to BotService */
    export interface BotService extends BotServiceBase {
        Name: string;
        ProvisionInfo: AzureProvisionInfo;
        CreatedDate: DateTimeOffset;
        LastSavedDate: DateTimeOffset;
        OwnerIds: string[];
        Hashtags: string[];
        Publisher: string;
        PublisherEmail: string;
        Website: string;
        Endpoints: Endpoint[];
        IconUrl: string;
        Description: string;
        ListedChannels: string[];
        ListedMarkets: string[];
        Listed: boolean;
        Submitted: boolean;
        DefaultIconColorHash: string;
        Contacts: ContactInfo[];
        FeaturedDisplayOrder: number;
        DeveloperAppInsightsAPIKey: string;
        DeveloperAppInsightsApplicationId: string;
        DeveloperBotPrivacyUrl: string;
        DeveloperBotTermsOfUseUrl: string;
        CortanaProperties: CortanaProperties;
        ApsProperties: ApsProperties;
        ManifestUrl: string;
        Actions: Action[];
        LUISAppIds: string[];
        LUISKey: string;
        BotStateTable: string;
        BotStatePriorTable: string;
        BotServiceRuntimeVersion: string;
        AzureTags: string;
        AcceptedTermsOfService: string[];
        MigratedDate: DateTimeOffset;
        ProvisioningState: string;
        IsStateStoreProhibited: boolean;
        Languages: string[];
        Roles: string[];
        OpenWithHint: string;
        AppPasswordHint: string;
        MsaAppType: BotAppType;
        MsaAppMSIResourceId: string;
        ApprovedForBotDirectory: boolean;
    }

    /** Corresponds to FormValidationErrors, optional properties */
    export interface _FormValidationErrors {
        Errors?: FieldValidationError[];
    }

    /** Corresponds to FormValidationErrors */
    export interface FormValidationErrors {
        Errors: FieldValidationError[];
    }

    /** Corresponds to BotLog, optional properties */
    export interface _BotLog extends _ETagObject {
        BotId?: string;
        ChannelId?: string;
        CorrelationId?: string;
        Message?: string;
        When?: DateTimeOffset;
        Severity?: SeverityType;
        Slot?: number;
    }

    /** Corresponds to BotLog */
    export interface BotLog extends ETagObject {
        BotId: string;
        ChannelId: string;
        CorrelationId: string;
        Message: string;
        When: DateTimeOffset;
        Severity: SeverityType;
        Slot: number;
    }

    /** Corresponds to AppConfigProperties, optional properties */
    export interface _AppConfigProperties {
        numberOfWorkers?: number;
        defaultDocuments?: string[];
        netFrameworkVersion?: string;
        phpVersion?: string;
        pythonVersion?: string;
        nodeVersion?: string;
        requestTracingEnabled?: boolean;
        remoteDebuggingEnabled?: boolean;
        remoteDebuggingVersion?: any;
        httpLoggingEnabled?: boolean;
        logsDirectorySizeLimit?: number;
        detailedErrorLoggingEnabled?: boolean;
        publishingUsername?: string;
        publishingPassword?: any;
        appSettings?: any;
        metadata?: any;
        connectionStrings?: any;
        machineKey?: any;
        handlerMappings?: any;
        documentRoot?: any;
        scmType?: string;
        use32BitWorkerProcess?: boolean;
        webSocketsEnabled?: boolean;
        alwaysOn?: boolean;
        javaVersion?: any;
        javaContainer?: any;
        javaContainerVersion?: any;
        appCommandLine?: string;
        managedPipelineMode?: number;
        virtualApplications?: Virtualapplication[];
        winAuthAdminState?: number;
        winAuthTenantState?: number;
        customAppPoolIdentityAdminState?: boolean;
        customAppPoolIdentityTenantState?: boolean;
        runtimeADUser?: any;
        runtimeADUserPassword?: any;
        loadBalancing?: number;
        routingRules?: any[];
        experiments?: Experiments;
        limits?: any;
        autoHealEnabled?: boolean;
        autoHealRules?: Autohealrules;
        tracingOptions?: any;
        vnetName?: string;
        siteAuthEnabled?: boolean;
        siteAuthSettings?: Siteauthsettings;
        cors?: Cors;
        push?: any;
        apiDefinition?: any;
        autoSwapSlotName?: any;
        localMySqlEnabled?: boolean;
        ipSecurityRestrictions?: any;
    }

    /** Corresponds to AppConfigProperties */
    export interface AppConfigProperties {
        numberOfWorkers: number;
        defaultDocuments: string[];
        netFrameworkVersion: string;
        phpVersion: string;
        pythonVersion: string;
        nodeVersion: string;
        requestTracingEnabled: boolean;
        remoteDebuggingEnabled: boolean;
        remoteDebuggingVersion: any;
        httpLoggingEnabled: boolean;
        logsDirectorySizeLimit: number;
        detailedErrorLoggingEnabled: boolean;
        publishingUsername: string;
        publishingPassword: any;
        appSettings: any;
        metadata: any;
        connectionStrings: any;
        machineKey: any;
        handlerMappings: any;
        documentRoot: any;
        scmType: string;
        use32BitWorkerProcess: boolean;
        webSocketsEnabled: boolean;
        alwaysOn: boolean;
        javaVersion: any;
        javaContainer: any;
        javaContainerVersion: any;
        appCommandLine: string;
        managedPipelineMode: number;
        virtualApplications: Virtualapplication[];
        winAuthAdminState: number;
        winAuthTenantState: number;
        customAppPoolIdentityAdminState: boolean;
        customAppPoolIdentityTenantState: boolean;
        runtimeADUser: any;
        runtimeADUserPassword: any;
        loadBalancing: number;
        routingRules: any[];
        experiments: Experiments;
        limits: any;
        autoHealEnabled: boolean;
        autoHealRules: Autohealrules;
        tracingOptions: any;
        vnetName: string;
        siteAuthEnabled: boolean;
        siteAuthSettings: Siteauthsettings;
        cors: Cors;
        push: any;
        apiDefinition: any;
        autoSwapSlotName: any;
        localMySqlEnabled: boolean;
        ipSecurityRestrictions: any;
    }

    /** Corresponds to DevPortalLib.Azure.AzureResource<T>, optional properties */
    export interface _AzureResource<T> {
        name?: string;
        id?: string;
        type?: string;
        location?: string;
        properties?: T;
    }

    /** Corresponds to DevPortalLib.Azure.AzureResource<T> */
    export interface AzureResource<T> {
        name: string;
        id: string;
        type: string;
        location: string;
        properties: T;
    }

    /** Corresponds to BotHistory, optional properties */
    export interface _BotHistory extends _ETagObject {
        BotId?: string;
        Type?: PublishEvent;
        Comment?: string;
        User?: string;
        When?: DateTimeOffset;
        Channel?: string;
        SubmitDate?: DateTimeOffset;
        ReviewDate?: DateTimeOffset;
        PublisherEmail?: string;
        BotName?: string;
        SubmittedBy?: string;
        NormalizedBotId?: string;
    }

    /** Corresponds to BotHistory */
    export interface BotHistory extends ETagObject {
        BotId: string;
        Type: PublishEvent;
        Comment: string;
        User: string;
        When: DateTimeOffset;
        Channel: string;
        SubmitDate: DateTimeOffset;
        ReviewDate: DateTimeOffset;
        PublisherEmail: string;
        BotName: string;
        SubmittedBy: string;
        NormalizedBotId: string;
    }

    /** Corresponds to PublishEvent */
    export enum PublishEvent {
        SUBMIT = 0, 
        UNSUBMIT = 1, 
        INREVIEW = 2, 
        REJECT = 3, 
        ACCEPT = 4, 
        FEATURED = 5, 
        UNFEATURED = 6, 
        SCANNING = 7, 
        SCANNED = 8, 
        DELETED = 9, 
        SCANFAILED = 10
    }

    /** Corresponds to Channel, optional properties */
    export interface _Channel extends _ETagObject {
        Id?: string;
        Name?: string;
        Description?: string;
        Endpoint?: string;
        UserConfigurationUrl?: string;
        DeveloperConfigurationUrl?: string;
        DeveloperConfigLogoutUrl?: string;
        IconUrl?: string;
        SecondaryIconUrl?: string;
        UserConfigurationTemplate?: string;
        SystemStatus?: string;
    }

    /** Corresponds to Channel */
    export interface Channel extends ETagObject {
        Id: string;
        Name: string;
        Description: string;
        Endpoint: string;
        UserConfigurationUrl: string;
        DeveloperConfigurationUrl: string;
        DeveloperConfigLogoutUrl: string;
        IconUrl: string;
        SecondaryIconUrl: string;
        UserConfigurationTemplate: string;
        SystemStatus: string;
    }

    /** Corresponds to BotContactCard, optional properties */
    export interface _BotContactCard {
        Id?: string;
        Name?: string;
        CreatedDate?: DateTimeOffset;
        Languages?: string[];
        Hashtags?: string[];
        Publisher?: string;
        PublisherEmail?: string;
        Website?: string;
        IconUrl?: string;
        Description?: string;
        Contacts?: ContactCard[];
        DeveloperBotTermsOfUseUrl?: string;
        ChannelName?: string;
        ChannelIconUrl?: string;
        VCardUrl?: string;
    }

    /** Corresponds to BotContactCard */
    export interface BotContactCard {
        Id: string;
        Name: string;
        CreatedDate: DateTimeOffset;
        Languages: string[];
        Hashtags: string[];
        Publisher: string;
        PublisherEmail: string;
        Website: string;
        IconUrl: string;
        Description: string;
        Contacts: ContactCard[];
        DeveloperBotTermsOfUseUrl: string;
        ChannelName: string;
        ChannelIconUrl: string;
        VCardUrl: string;
    }

    /** Corresponds to LuisAppData, optional properties */
    export interface _LuisAppData {
        AppId?: string;
        Key?: string;
        APIHostName?: string;
    }

    /** Corresponds to LuisAppData */
    export interface LuisAppData {
        AppId: string;
        Key: string;
        APIHostName: string;
    }

    /** Corresponds to LuisRegions */
    export enum LuisRegions {
        US = 0, 
        EU = 1, 
        AU = 2, 
        Virginia = 3, 
        USNATEAST = 4, 
        USNATWEST = 5, 
        USSECEAST = 6, 
        USSECWEST = 7, 
        CHINAEAST2 = 8, 
        CHINANORTH = 9
    }

    /** Corresponds to SchemaTransformationVersion, optional properties */
    export interface _SchemaTransformationVersion {
        Major?: number;
        Minor?: number;
    }

    /** Corresponds to SchemaTransformationVersion */
    export interface SchemaTransformationVersion {
        Major: number;
        Minor: number;
    }

    /** Corresponds to VfsNodeInfo, optional properties */
    export interface _VfsNodeInfo {
        name?: string;
        size?: number;
        mtime?: DateTimeOffset;
        ctime?: DateTimeOffset;
        href?: string;
        path?: string;
        mime?: string;
    }

    /** Corresponds to VfsNodeInfo */
    export interface VfsNodeInfo {
        name: string;
        size: number;
        mtime: DateTimeOffset;
        ctime: DateTimeOffset;
        href: string;
        path: string;
        mime: string;
    }

    /** Corresponds to ScmAuthorization, optional properties */
    export interface _ScmAuthorization {
        Url?: string;
        UserName?: string;
        Password?: string;
    }

    /** Corresponds to ScmAuthorization */
    export interface ScmAuthorization {
        Url: string;
        UserName: string;
        Password: string;
    }

    /** Corresponds to BotDevInfo, optional properties */
    export interface _BotDevInfo {
        ResourceId?: string;
        AuthToken?: string;
    }

    /** Corresponds to BotDevInfo */
    export interface BotDevInfo {
        ResourceId: string;
        AuthToken: string;
    }

    /** Corresponds to ConvergedApp, optional properties */
    export interface _ConvergedApp {
        Id?: string;
        AppId?: string;
        Password?: string;
        Name?: string;
        SignInAudience?: string;
        TenantId?: string;
    }

    /** Corresponds to ConvergedApp */
    export interface ConvergedApp {
        Id: string;
        AppId: string;
        Password: string;
        Name: string;
        SignInAudience: string;
        TenantId: string;
    }

    /** Corresponds to CheckNameAvailabilityResponse, optional properties */
    export interface _CheckNameAvailabilityResponse {
        Valid?: boolean;
        Message?: string;
        AbsCode?: string;
    }

    /** Corresponds to CheckNameAvailabilityResponse */
    export interface CheckNameAvailabilityResponse {
        Valid: boolean;
        Message: string;
        AbsCode: string;
    }

    /** Corresponds to CheckFeatureEnabledResponse, optional properties */
    export interface _CheckFeatureEnabledResponse {
        IsEnabled?: boolean;
        Message?: string;
    }

    /** Corresponds to CheckFeatureEnabledResponse */
    export interface CheckFeatureEnabledResponse {
        IsEnabled: boolean;
        Message: string;
    }

    /** Corresponds to EmailSubscriptionInfo, optional properties */
    export interface _EmailSubscriptionInfo {
        CountryCode?: string;
        Subscription?: EmailSubscription;
        IsEnabled?: boolean;
        SubscriptionType?: EmailOptingOptions;
    }

    /** Corresponds to EmailSubscriptionInfo */
    export interface EmailSubscriptionInfo {
        CountryCode: string;
        Subscription: EmailSubscription;
        IsEnabled: boolean;
        SubscriptionType: EmailOptingOptions;
    }

    /** Corresponds to UserAgreementConsentInfo, optional properties */
    export interface _UserAgreementConsentInfo {
        ConsentVersion?: UserAgreementConsentVersion;
    }

    /** Corresponds to UserAgreementConsentInfo */
    export interface UserAgreementConsentInfo {
        ConsentVersion: UserAgreementConsentVersion;
    }

    /** Corresponds to AnalyticsNumericTimelineResponse, optional properties */
    export interface _AnalyticsNumericTimelineResponse {
        Timeline?: any;
        Totals?: any;
    }

    /** Corresponds to AnalyticsNumericTimelineResponse */
    export interface AnalyticsNumericTimelineResponse {
        Timeline: any;
        Totals: any;
    }

    /** Corresponds to AnalyticsRequest, optional properties */
    export interface _AnalyticsRequest {
        Timespan?: string;
    }

    /** Corresponds to AnalyticsRequest */
    export interface AnalyticsRequest {
        Timespan: string;
    }

    /** Corresponds to AnalyticsUserRetentionResponse, optional properties */
    export interface _AnalyticsUserRetentionResponse {
        Days?: any;
    }

    /** Corresponds to AnalyticsUserRetentionResponse */
    export interface AnalyticsUserRetentionResponse {
        Days: any;
    }

    /** Corresponds to AnswerManifest, optional properties */
    export interface _AnswerManifest {
        bfModel?: BotService;
        Id?: string;
        AppId?: string;
        Endpoint?: string;
        Name?: string;
        Description?: string;
        DeveloperUserIds?: any;
        NeedsOboAuth?: boolean;
        ResourceId?: string;
        TriggerPhrases?: any;
        Status?: AnswerStatus;
        Icon?: string;
        SupportContact?: string;
        AdditionalComments?: string;
        SampleQueries?: any;
        VerticalName?: string;
    }

    /** Corresponds to AnswerManifest */
    export interface AnswerManifest {
        bfModel: BotService;
        Id: string;
        AppId: string;
        Endpoint: string;
        Name: string;
        Description: string;
        DeveloperUserIds: any;
        NeedsOboAuth: boolean;
        ResourceId: string;
        TriggerPhrases: any;
        Status: AnswerStatus;
        Icon: string;
        SupportContact: string;
        AdditionalComments: string;
        SampleQueries: any;
        VerticalName: string;
    }

    /** Corresponds to Uri, optional properties */
    export interface _Uri {
        AbsolutePath?: string;
        AbsoluteUri?: string;
        LocalPath?: string;
        Authority?: string;
        HostNameType?: UriHostNameType;
        IsDefaultPort?: boolean;
        IsFile?: boolean;
        IsLoopback?: boolean;
        PathAndQuery?: string;
        Segments?: string[];
        IsUnc?: boolean;
        Host?: string;
        Port?: number;
        Query?: string;
        Fragment?: string;
        Scheme?: string;
        OriginalString?: string;
        DnsSafeHost?: string;
        IdnHost?: string;
        IsAbsoluteUri?: boolean;
        UserEscaped?: boolean;
        UserInfo?: string;
    }

    /** Corresponds to Uri */
    export interface Uri {
        AbsolutePath: string;
        AbsoluteUri: string;
        LocalPath: string;
        Authority: string;
        HostNameType: UriHostNameType;
        IsDefaultPort: boolean;
        IsFile: boolean;
        IsLoopback: boolean;
        PathAndQuery: string;
        Segments: string[];
        IsUnc: boolean;
        Host: string;
        Port: number;
        Query: string;
        Fragment: string;
        Scheme: string;
        OriginalString: string;
        DnsSafeHost: string;
        IdnHost: string;
        IsAbsoluteUri: boolean;
        UserEscaped: boolean;
        UserInfo: string;
    }

    /** Corresponds to ConnectionSettingInfo, optional properties */
    export interface _ConnectionSettingInfo {
        Id?: string;
        Name?: string;
        ServiceProviderId?: string;
    }

    /** Corresponds to ConnectionSettingInfo */
    export interface ConnectionSettingInfo {
        Id: string;
        Name: string;
        ServiceProviderId: string;
    }

    /** Corresponds to ChannelViewModel, optional properties */
    export interface _ChannelViewModel {
        Id?: string;
        Name?: string;
        Description?: string;
        DeveloperConfigurationUrl?: string;
        DeveloperConfigLogoutUrl?: string;
        IconUrl?: string;
        SystemStatus?: string;
    }

    /** Corresponds to ChannelViewModel */
    export interface ChannelViewModel {
        Id: string;
        Name: string;
        Description: string;
        DeveloperConfigurationUrl: string;
        DeveloperConfigLogoutUrl: string;
        IconUrl: string;
        SystemStatus: string;
    }

    /** Corresponds to DevPortalFeatureFlags */
    export enum DevPortalFeatureFlags {
        None = 0, 
        SupportNonAbsCreate = 1, 
        SupportNonAzureUsers = 2
    }

    /** Corresponds to ETagObject, optional properties */
    export interface _ETagObject {
        ETag?: string;
    }

    /** Corresponds to ETagObject */
    export interface ETagObject {
        ETag: string;
    }

    /** Corresponds to AnnouncementSeverity */
    export enum AnnouncementSeverity {
        Good = 0, 
        Warning = 1, 
        Error = 2, 
        Information = 3
    }

    /** Corresponds to IBot, optional properties */
    export interface _IBot {
        Id?: string;
        Name?: string;
        Languages?: string[];
        OwnerIds?: string[];
        Hashtags?: string[];
        Publisher?: string;
        PublisherEmail?: string;
        IconUrl?: string;
        Description?: string;
        EnabledChannels?: string[];
        ListedChannels?: string[];
        Listed?: boolean;
        ApprovedForBotDirectory?: boolean;
        DefaultIconColorHash?: string;
        OpenWithHint?: string;
        AppPasswordHint?: string;
    }

    /** Corresponds to IBot */
    export interface IBot {
        Id: string;
        Name: string;
        Languages: string[];
        OwnerIds: string[];
        Hashtags: string[];
        Publisher: string;
        PublisherEmail: string;
        IconUrl: string;
        Description: string;
        EnabledChannels: string[];
        ListedChannels: string[];
        Listed: boolean;
        ApprovedForBotDirectory: boolean;
        DefaultIconColorHash: string;
        OpenWithHint: string;
        AppPasswordHint: string;
    }

    /** Corresponds to DateTimeOffset, optional properties */
    export interface _DateTimeOffset {
        DateTime?: Date;
        UtcDateTime?: Date;
        LocalDateTime?: Date;
        Date?: Date;
        Day?: number;
        DayOfWeek?: DayOfWeek;
        DayOfYear?: number;
        Hour?: number;
        Millisecond?: number;
        Minute?: number;
        Month?: number;
        Offset?: TimeSpan;
        Second?: number;
        Ticks?: number;
        UtcTicks?: number;
        TimeOfDay?: TimeSpan;
        Year?: number;
    }

    /** Corresponds to DateTimeOffset */
    export interface DateTimeOffset {
        DateTime: Date;
        UtcDateTime: Date;
        LocalDateTime: Date;
        Date: Date;
        Day: number;
        DayOfWeek: DayOfWeek;
        DayOfYear: number;
        Hour: number;
        Millisecond: number;
        Minute: number;
        Month: number;
        Offset: TimeSpan;
        Second: number;
        Ticks: number;
        UtcTicks: number;
        TimeOfDay: TimeSpan;
        Year: number;
    }

    /** Corresponds to BotAppType */
    export enum BotAppType {
        MultiTenant = 0, 
        SingleTenant = 1, 
        UserAssignedMSI = 2
    }

    /** Corresponds to D, optional properties */
    export interface _D {
    }

    /** Corresponds to D */
    export interface D {
    }

    /** Corresponds to ReviewStatusDetail, optional properties */
    export interface _ReviewStatusDetail {
        ReviewStatus?: ReviewStatus;
        Comment?: string;
    }

    /** Corresponds to ReviewStatusDetail */
    export interface ReviewStatusDetail {
        ReviewStatus: ReviewStatus;
        Comment: string;
    }

    /** Corresponds to TenantAccess */
    export enum TenantAccess {
        NoAccess = 0, 
        NotCurrent = 1, 
        Current = 2
    }

    /** Corresponds to BotServiceBase, optional properties */
    export interface _BotServiceBase extends _ETagObject {
        Id?: string;
        MsaAppId?: string;
        MsaAppTenantId?: string;
        SubscriptionId?: string;
        ResourceGroupName?: string;
        Kind?: string;
        ServicePlan?: string;
        IsSubscriptionDisabled?: boolean;
        ThrottlePolicy?: string;
        ExceededMeters?: ExceededMeter[];
        Endpoint?: string;
        Version?: string;
        DeveloperAppInsightKey?: string;
        ConfiguredChannels?: string[];
        EnabledChannels?: string[];
        IsStreamingSupported?: boolean;
        SchemaTransformationVersion?: SchemaTransformationVersion;
        SystemHashtags?: string[];
        IsIsolated?: boolean;
        Region?: string;
    }

    /** Corresponds to BotServiceBase */
    export interface BotServiceBase extends ETagObject {
        Id: string;
        MsaAppId: string;
        MsaAppTenantId: string;
        SubscriptionId: string;
        ResourceGroupName: string;
        Kind: string;
        ServicePlan: string;
        IsSubscriptionDisabled: boolean;
        ThrottlePolicy: string;
        ExceededMeters: ExceededMeter[];
        Endpoint: string;
        Version: string;
        DeveloperAppInsightKey: string;
        ConfiguredChannels: string[];
        EnabledChannels: string[];
        IsStreamingSupported: boolean;
        SchemaTransformationVersion: SchemaTransformationVersion;
        SystemHashtags: string[];
        IsIsolated: boolean;
        Region: string;
    }

    /** Corresponds to IManagementMessageBot, optional properties */
    export interface _IManagementMessageBot {
        Id?: string;
        MsaAppId?: string;
        SubscriptionId?: string;
        ResourceGroupName?: string;
    }

    /** Corresponds to IManagementMessageBot */
    export interface IManagementMessageBot {
        Id: string;
        MsaAppId: string;
        SubscriptionId: string;
        ResourceGroupName: string;
    }

    /** Corresponds to IRedactable, optional properties */
    export interface _IRedactable {
    }

    /** Corresponds to IRedactable */
    export interface IRedactable {
    }

    /** Corresponds to AzureProvisionInfo, optional properties */
    export interface _AzureProvisionInfo {
        SubscriptionId?: string;
        Region?: string;
        ResourceGroupName?: string;
        Deployment?: any;
        IntegrationDeployment?: any;
        HostingEnvironment?: HostingEnvironment;
        SourceRepoUrl?: string;
        SiteName?: string;
        ServerFarm?: string;
        TenantId?: string;
        BotRunTimeVersion?: string;
        ServerFarmId?: string;
    }

    /** Corresponds to AzureProvisionInfo */
    export interface AzureProvisionInfo {
        SubscriptionId: string;
        Region: string;
        ResourceGroupName: string;
        Deployment: any;
        IntegrationDeployment: any;
        HostingEnvironment: HostingEnvironment;
        SourceRepoUrl: string;
        SiteName: string;
        ServerFarm: string;
        TenantId: string;
        BotRunTimeVersion: string;
        ServerFarmId: string;
    }

    /** Corresponds to Endpoint, optional properties */
    export interface _Endpoint {
        Version?: string;
        Url?: string;
    }

    /** Corresponds to Endpoint */
    export interface Endpoint {
        Version: string;
        Url: string;
    }

    /** Corresponds to ContactInfo, optional properties */
    export interface _ContactInfo {
        ChannelId?: string;
        Address?: string;
        ContactType?: ContactType;
        Name?: string;
        HtmlLink?: string;
        Unpublishable?: boolean;
        UnpublishableReason?: string;
    }

    /** Corresponds to ContactInfo */
    export interface ContactInfo {
        ChannelId: string;
        Address: string;
        ContactType: ContactType;
        Name: string;
        HtmlLink: string;
        Unpublishable: boolean;
        UnpublishableReason: string;
    }

    /** Corresponds to CortanaProperties, optional properties */
    export interface _CortanaProperties {
        InvocationName?: string;
        ModuleName?: string;
    }

    /** Corresponds to CortanaProperties */
    export interface CortanaProperties {
        InvocationName: string;
        ModuleName: string;
    }

    /** Corresponds to ApsProperties, optional properties */
    export interface _ApsProperties {
        DeploymentEnvironment?: ApsDeploymentEnvironment;
    }

    /** Corresponds to ApsProperties */
    export interface ApsProperties {
        DeploymentEnvironment: ApsDeploymentEnvironment;
    }

    /** Corresponds to Action, optional properties */
    export interface _Action {
        Id?: string;
        ActionDefinition?: ActionDefinition;
    }

    /** Corresponds to Action */
    export interface Action {
        Id: string;
        ActionDefinition: ActionDefinition;
    }

    /** Corresponds to FieldValidationError, optional properties */
    export interface _FieldValidationError {
        Field?: string;
        Message?: string;
    }

    /** Corresponds to FieldValidationError */
    export interface FieldValidationError {
        Field: string;
        Message: string;
    }

    /** Corresponds to SeverityType */
    export enum SeverityType {
        OK = 0, 
        Warning = 1, 
        Error = 2
    }

    /** Corresponds to Virtualapplication, optional properties */
    export interface _Virtualapplication {
        virtualPath?: string;
        physicalPath?: string;
        preloadEnabled?: boolean;
        virtualDirectories?: any;
    }

    /** Corresponds to Virtualapplication */
    export interface Virtualapplication {
        virtualPath: string;
        physicalPath: string;
        preloadEnabled: boolean;
        virtualDirectories: any;
    }

    /** Corresponds to Experiments, optional properties */
    export interface _Experiments {
        rampUpRules?: any[];
    }

    /** Corresponds to Experiments */
    export interface Experiments {
        rampUpRules: any[];
    }

    /** Corresponds to Autohealrules, optional properties */
    export interface _Autohealrules {
        triggers?: any;
        actions?: any;
    }

    /** Corresponds to Autohealrules */
    export interface Autohealrules {
        triggers: any;
        actions: any;
    }

    /** Corresponds to Siteauthsettings, optional properties */
    export interface _Siteauthsettings {
        enabled?: any;
        httpApiPrefixPath?: any;
        unauthenticatedClientAction?: any;
        tokenStoreEnabled?: any;
        allowedExternalRedirectUrls?: any;
        defaultProvider?: any;
        clientId?: any;
        clientSecret?: any;
        issuer?: any;
        allowedAudiences?: any;
        additionalLoginParams?: any;
        isAadAutoProvisioned?: boolean;
        googleClientId?: any;
        googleClientSecret?: any;
        googleOAuthScopes?: any;
        facebookAppId?: any;
        facebookAppSecret?: any;
        facebookOAuthScopes?: any;
        twitterConsumerKey?: any;
        twitterConsumerSecret?: any;
        microsoftAccountClientId?: any;
        microsoftAccountClientSecret?: any;
        microsoftAccountOAuthScopes?: any;
    }

    /** Corresponds to Siteauthsettings */
    export interface Siteauthsettings {
        enabled: any;
        httpApiPrefixPath: any;
        unauthenticatedClientAction: any;
        tokenStoreEnabled: any;
        allowedExternalRedirectUrls: any;
        defaultProvider: any;
        clientId: any;
        clientSecret: any;
        issuer: any;
        allowedAudiences: any;
        additionalLoginParams: any;
        isAadAutoProvisioned: boolean;
        googleClientId: any;
        googleClientSecret: any;
        googleOAuthScopes: any;
        facebookAppId: any;
        facebookAppSecret: any;
        facebookOAuthScopes: any;
        twitterConsumerKey: any;
        twitterConsumerSecret: any;
        microsoftAccountClientId: any;
        microsoftAccountClientSecret: any;
        microsoftAccountOAuthScopes: any;
    }

    /** Corresponds to Cors, optional properties */
    export interface _Cors {
        allowedOrigins?: string[];
    }

    /** Corresponds to Cors */
    export interface Cors {
        allowedOrigins: string[];
    }

    /** Corresponds to T, optional properties */
    export interface _T {
    }

    /** Corresponds to T */
    export interface T {
    }

    /** Corresponds to ContactCard, optional properties */
    export interface _ContactCard {
        ChannelId?: string;
        ContactType?: string;
        ChannelName?: string;
        ChannelIconUrl?: string;
        Name?: string;
        Address?: string;
        HtmlLink?: string;
        Secret?: string;
        Note?: string;
    }

    /** Corresponds to ContactCard */
    export interface ContactCard {
        ChannelId: string;
        ContactType: string;
        ChannelName: string;
        ChannelIconUrl: string;
        Name: string;
        Address: string;
        HtmlLink: string;
        Secret: string;
        Note: string;
    }

    /** Corresponds to EmailSubscription, optional properties */
    export interface _EmailSubscription {
        ConsentVersion?: EmailSubscriptionTermsAndConditions;
        SubscriptionToken?: string;
        HasConsent?: boolean;
    }

    /** Corresponds to EmailSubscription */
    export interface EmailSubscription {
        ConsentVersion: EmailSubscriptionTermsAndConditions;
        SubscriptionToken: string;
        HasConsent: boolean;
    }

    /** Corresponds to EmailOptingOptions */
    export enum EmailOptingOptions {
        OptOut = 0, 
        OptIn = 1, 
        DoubleOptIn = 2, 
        Notice = 3
    }

    /** Corresponds to UserAgreementConsentVersion */
    export enum UserAgreementConsentVersion {
        V1 = 1, 
        V2 = 2
    }

    /** Corresponds to AnswerStatus */
    export enum AnswerStatus {
        NeedsReview = 0, 
        InReview = 1, 
        Published = 2, 
        Rejected = 3, 
        Deactivated = 4
    }

    /** Corresponds to UriHostNameType */
    export enum UriHostNameType {
        Unknown = 0, 
        Basic = 1, 
        Dns = 2, 
        IPv4 = 3, 
        IPv6 = 4
    }

    /** Corresponds to DayOfWeek */
    export enum DayOfWeek {
        Sunday = 0, 
        Monday = 1, 
        Tuesday = 2, 
        Wednesday = 3, 
        Thursday = 4, 
        Friday = 5, 
        Saturday = 6
    }

    /** Corresponds to TimeSpan, optional properties */
    export interface _TimeSpan {
        Ticks?: number;
        Days?: number;
        Hours?: number;
        Milliseconds?: number;
        Minutes?: number;
        Seconds?: number;
        TotalDays?: number;
        TotalHours?: number;
        TotalMilliseconds?: number;
        TotalMinutes?: number;
        TotalSeconds?: number;
    }

    /** Corresponds to TimeSpan */
    export interface TimeSpan {
        Ticks: number;
        Days: number;
        Hours: number;
        Milliseconds: number;
        Minutes: number;
        Seconds: number;
        TotalDays: number;
        TotalHours: number;
        TotalMilliseconds: number;
        TotalMinutes: number;
        TotalSeconds: number;
    }

    /** Corresponds to ReviewStatus */
    export enum ReviewStatus {
        NotSubmitted = 0, 
        InReview = 1, 
        Rejected = 2, 
        Approved = 3
    }

    /** Corresponds to ExceededMeter, optional properties */
    export interface _ExceededMeter {
        MeterId?: string;
        Expires?: Date;
    }

    /** Corresponds to ExceededMeter */
    export interface ExceededMeter {
        MeterId: string;
        Expires: Date;
    }

    /** Corresponds to HostingEnvironment */
    export enum HostingEnvironment {
        AzureWebApp = 0, 
        AzureFunctions = 1, 
        AzureBotApp = 2, 
        AzureConversationDesigner = 3
    }

    /** Corresponds to ContactType */
    export enum ContactType {
        Unknown = 0, 
        Email = 1, 
        GroupMe = 2, 
        Kik = 3, 
        PhoneNumber = 4, 
        Skype = 5, 
        Slack = 6, 
        Telegram = 7, 
        Twitter = 8, 
        Web = 9, 
        API = 10, 
        Facebook = 11, 
        Instagram = 12, 
        MSTeams = 13, 
        WeChat = 14, 
        SIP = 15, 
        Bing = 16, 
        Kaizala = 17, 
        Line = 18
    }

    /** Corresponds to ApsDeploymentEnvironment */
    export enum ApsDeploymentEnvironment {
        CommercialDeployment = 0, 
        GCCModerateDeployment = 1, 
        GCCHighDeployment = 2, 
        DoDDeployment = 3
    }

    /** Corresponds to ActionDefinition, optional properties */
    export interface _ActionDefinition {
        Id?: string;
        Description?: string;
        Slots?: SlotDefinition[];
        Triggers?: TriggerSet;
    }

    /** Corresponds to ActionDefinition */
    export interface ActionDefinition {
        Id: string;
        Description: string;
        Slots: SlotDefinition[];
        Triggers: TriggerSet;
    }

    /** Corresponds to EmailSubscriptionTermsAndConditions */
    export enum EmailSubscriptionTermsAndConditions {
        V1 = 1, 
        V2 = 2
    }

    /** Corresponds to SlotDefinition, optional properties */
    export interface _SlotDefinition {
        Name?: string;
        DesiredType?: string;
    }

    /** Corresponds to SlotDefinition */
    export interface SlotDefinition {
        Name: string;
        DesiredType: string;
    }

    /** Corresponds to TriggerSet, optional properties */
    export interface _TriggerSet {
        Utterances?: UtteranceDefinition[];
    }

    /** Corresponds to TriggerSet */
    export interface TriggerSet {
        Utterances: UtteranceDefinition[];
    }

    /** Corresponds to UtteranceDefinition, optional properties */
    export interface _UtteranceDefinition {
        Text?: string;
        Locale?: string;
        Entities?: EntityReference;
    }

    /** Corresponds to UtteranceDefinition */
    export interface UtteranceDefinition {
        Text: string;
        Locale: string;
        Entities: EntityReference;
    }

    /** Corresponds to EntityReference, optional properties */
    export interface _EntityReference {
        Name?: string;
        StartPosition?: string;
        EndPosition?: string;
    }

    /** Corresponds to EntityReference */
    export interface EntityReference {
        Name: string;
        StartPosition: string;
        EndPosition: string;
    }
